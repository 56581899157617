import React, { useEffect, useState } from "react";
import facebook from "../assets/images/facebook.png";
// import twitter from '../assets/images/twitter.png';
import youtube from "../assets/images/youtube.png";
// import snapchat from '../assets/images/snapchat.png'
// import instagram from '../assets/images/instagram.png';
import email from "../assets/images/email.png";

import { useHistory } from "react-router-dom";
import { listLandingPageContent } from "./api/LandingPageApi";
import CreateNotifications from "./Notifications";

function Footer() {
  const history = useHistory();
  const [data, setData] = useState({});

  useEffect(() => {
    const initialLoad = async () => {
      await listLandingPageContent()
        .then((res) => {
          if (res.type === "success") setData(JSON.parse(res.data.socialMedia));
          else throw res;
        })
        .catch((err) => {
          CreateNotifications("error", err.message);
        });
    };
    initialLoad();
  }, []);

  return !(
    history.location.pathname.includes("/player") ||
    history.location.pathname.includes("/admin")
  ) ? (
    <footer className="footer">
      <section className="footer_container">
        <div className="footer_title">
          <div className="title">
            <h3>Follow Us</h3>
          </div>
        </div>
        <Platform
          href={data.facebook}
          title="Facebook"
          name="waiitt sports"
          image={facebook}
        />
        {/* {data.twitter ? (
          <Platform
            href={data.twitter}
            title="Twitter"
            name="757Sports"
            image={twitter}
          />
        ) : null} */}
        {/* {data.snapchat ? (
          <Platform
            href={data.snapchat}
            title="Snapchat"
            name="757Sports"
            image={snapchat}
          />
        ) : null} */}
        <Platform
          href={data.youtube}
          title="Youtube"
          name="waiitt sports"
          image={youtube}
        />
        <Platform
          href={data.email}
          title="Email"
          name="waiitt sports"
          image={email}
        />
        {/* {data.instagram ? (
          <Platform
            href={data.instagram}
            title="Instagram"
            name="757Sports"
            image={instagram}
          />
        ) : null} */}
      </section>
    </footer>
  ) : null;
}

export default Footer;

const Platform = ({ image, title, name, href }) => {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      className="d3-l-col__col-4"
      href={title === "Email" ? `mailto:${href}` : href}
    >
      <div className="col-4__platform">
        <div className="image">
          <div className="circle">
            <img className="icon" src={image} alt={title} />
          </div>
        </div>
        <div className="detail">
          <h3>{title}</h3>
          <h5>{name}</h5>
        </div>
      </div>
    </a>
  );
};
