export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Name
        Location
        CoachName
        createdAt
        updatedAt
        Players {
          items {
            id
            Name
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
