import { API, graphqlOperation } from "aws-amplify";
import { createTeam, updateTeam, deleteTeam } from "../../graphql/mutations";
import { getTeam } from "../../graphql/queries";
import { listTeams } from "../../graphql/customQueries";

let responseData = {
  data: null,
  message: "Api not called",
  type: "error",
};

export const createTeamFunction = async (input) => {
  await API.graphql(graphqlOperation(createTeam, { input: input }))
    .then((res) => {
      responseData.data = res;
      responseData.message = "Successfully added the Team";
      responseData.type = "success";
    })
    .catch((err) => {
      responseData.data = null;
      responseData.message = "Something went wrong while adding the Team";
      responseData.type = "error";
    });

  return responseData;
};

export const updateTeamFunction = async (input) => {
  await API.graphql(graphqlOperation(updateTeam, { input: input }))
    .then((res) => {
      responseData.data = res;
      responseData.message = "Successfully updated the Team";
      responseData.type = "success";
    })
    .catch((err) => {
      responseData.data = null;
      responseData.message = "Something went wrong while updating the Team";
      responseData.type = "error";
    });

  return responseData;
};

export const deleteTeamFunction = async (id) => {
  await API.graphql(graphqlOperation(deleteTeam, { input: { id: id } }))
    .then((res) => {
      responseData.data = res;
      responseData.message = "Successfully deleted the Team";
      responseData.type = "success";
    })
    .catch((err) => {
      responseData.data = null;
      responseData.message = "Something went wrong while deleting the Team";
      responseData.type = "error";
    });

  return responseData;
};

export const listTeamsFunction = async () => {
  await API.graphql(graphqlOperation(listTeams))
    .then((res) => {
      responseData.data = res;
      responseData.message = "Successfully fetched the latest Team";
      responseData.type = "success";
    })
    .catch((err) => {
      responseData.data = null;
      responseData.message = "Something went wrong while fetching the Team";
      responseData.type = "error";
    });

  return responseData;
};

export const getTeamsFunction = async (id) => {
  await API.graphql(graphqlOperation(getTeam, { id: id }))
    .then((res) => {
      responseData.data = res;
      responseData.message = "Successfully fetched the Teams";
      responseData.type = "success";
    })
    .catch((err) => {
      responseData.data = null;
      responseData.message = "Something went wrong while fetching the Teams";
      responseData.type = "error";
    });

  return responseData;
};
