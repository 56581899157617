import React, { useEffect, useRef } from "react";
import ScreenWrapper from "../ScreenWrapper";
import AppButton from "../AppButton";
import AddButton from "../AddButton";
import Card from "../Card";
import { useState } from "react";
import UpdateRegistration from "../modals/updateRegistration";
import { DeleteRegistrations, ListRegistration } from "../api/registrations";
import CreateNotifications from "../Notifications";
import ViewRegistration from "../modals/ViewRegistration";

function Registrations() {
  const [modalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [view, setView] = useState(false);
  const [editData, setEditData] = useState(null);

  const isSubscribed = useRef(true);

  useEffect(() => {
    const initialLoad = async () => {
      await ListRegistration()
        .then((res) => {
          if (res.type === "success") {
            if (!isSubscribed.current) return;
            setData(res.data.data.listRegistrations.items);
          } else throw res;
        })
        .catch((err) => {
          CreateNotifications(err.type, err.message);
        });
    };
    initialLoad();
  }, []);

  const handleDelete = async (id) => {
    await DeleteRegistrations(id)
      .then((res) => {
        if (res.type === "success") {
          CreateNotifications(res.type, res.message);
        } else throw res;
      })
      .catch((err) => {
        CreateNotifications(err.type, err.message);
      });

    await updateAction();
  };

  const handleUpdate = (data) => {
    setEditData(data);
    setModalOpen(true);
  };

  const updateAction = async () => {
    await ListRegistration()
      .then((res) => {
        if (res.type === "success") {
          if (!isSubscribed.current) return;
          setData(res.data.data.listRegistrations.items);
        } else throw res;
      })
      .catch((err) => {
        CreateNotifications(err.type, err.message);
      });
  };

  return (
    <ScreenWrapper>
      {modalOpen && (
        <UpdateRegistration
          toUpdate={editData}
          setModalOpen={setModalOpen}
          updateAction={updateAction}
        />
      )}

      {view && <ViewRegistration setModalOpen={setView} data={editData} />}

      <div style={Styles.registrationHeader}>
        <div style={Styles.head}>
          <h1>Registrations</h1>
        </div>
        <div style={Styles.buttonContainer}>
          <AddButton
            onClick={() => {
              setModalOpen(!modalOpen);
              setEditData(null);
            }}
            title="Add New Registration"
          />
        </div>
      </div>

      <div style={Styles.parentCardContainer}>
        {data.map((item) => {
          return (
            <Card
              handleDelete={handleDelete}
              handleUpdate={handleUpdate}
              key={item.id}
              data={item}
              onClick={setEditData}
              setView={setView}
            />
          );
        })}
      </div>
    </ScreenWrapper>
  );
}

const Styles = {
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    flex: "1",
  },
  head: { display: "flex", flex: "1" },
  parentCardContainer: {
    display: "flex",
    flex: "2",
    padding: "10px",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  registrationHeader: {
    display: "flex",
    flex: "1",
    padding: "40px",
    justifyContent: "space-around",
  },
};

export default Registrations;
