import React, { useEffect, useState, useRef } from "react";
import { getCollection } from "../../api/Collection";
import ScreenWrapper from "../../ScreenWrapper";
import { Card, Row, Col } from "react-bootstrap";

function CollectionHome(props) {
  const [collections, setcollection] = useState([]);

  useEffect(async () => {
    getCollections();
    // console.log(props);
    // getTeams();
    // const resp = await listPlayersFunction();
    // console.log(resp);
  }, []);

  const getCollections = async () => {
    var response = await getCollection();
    setcollection(response.data);
    console.log("collection list", collections);
  };
  return (
    <ScreenWrapper>
      <div>
        <Row>
          {collections.map((colect, id) => (
            <Col lg={3}>
              <Card
                style={{ width: "100%" }}
                bg={"secondary"}
                // key={idx}
                text={"white"}
                style={{ width: "18rem" }}
                className="mb-2"
              >
                <Card.Header>{colect}</Card.Header>
                <Card.Body>
                  <Card.Title> Card Title </Card.Title>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <h1>Welcome to Collection</h1>;
      </div>
    </ScreenWrapper>
  );
}

export default CollectionHome;
