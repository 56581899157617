import React from "react";
import { colors } from "../config/color";
import { AiOutlineFileImage } from "react-icons/ai";

function SearchButton({ title, onClick, buttoncolor = "red" }) {
  return (
    <>
      <button
        onClick={onClick}
        style={{
          ...Styles.button,
          backgroundColor: colors[buttoncolor],
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <AiOutlineFileImage
            style={{
              marginRight: "10px",
              fontSize: "22px",
            }}
          />
          {title}
        </div>
      </button>
    </>
  );
}

const Styles = {
  button: {
    border: "none",
    color: "white",
    padding: "15px",
    textAlign: "center",
    textDecoration: "none",
    display: "inlineBlock",
    fontSize: "16px",
    fontWeight: "bold",
    borderRadius: "4px",
    width: "250px",
  },
};
export default SearchButton;
