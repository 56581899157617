import React, { PureComponent } from "react";
import { AiOutlineClose } from "react-icons/ai";
import ReactCrop from "react-image-crop";
import CreateNotifications from "./Notifications";

class ImageCropper extends PureComponent {
  state = {
    image: null,
    src: null,
    crop: {
      unit: "%",
      width: 30,
      aspect: 2 / 1,
    },
  };

  componentDidMount() {
    if ("aspectRatio" in this.props) {
      let cropnew = this.state.crop;
      cropnew["aspect"] = this.props.aspectRatio;
      // console.log(cropnew);
      this.setState({
        crop: cropnew,
      });
    }

    // console.log(this.props);
  }
  onSelectFile = (e) => {
    console.log(e);
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
      // console.log(e.target.files[0]["name"]);
      this.props.imageName(e.target.files[0]["name"]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          // console.error("Canvas is empty");
          CreateNotifications("error", "Image not found");
          return;
        }
        this.setState({ image: blob });
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  render() {
    const { crop, croppedImageUrl, src } = this.state;

    return (
      <div style={{ position: "relative" }} className="App">
        <div>
          <input type="file" accept="image/*" onChange={this.onSelectFile} />

          <AiOutlineClose
            color="#424242"
            size={36}
            style={{ position: "absolute", top: "3%", right: "5%" }}
            onClick={() => this.props.close()}
          />
        </div>
        {src && (
          <>
            <p style={{ color: "Red", padding: "10px" }}>
              Note : After Cropping. Click on Select Button at the Bottom
            </p>
            <ReactCrop
              src={src}
              crop={crop}
              ruleOfThirds
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
            />
          </>
        )}
        {/* {croppedImageUrl && (
          <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />
        )} */}

        {croppedImageUrl && (
          <button
            style={{ display: "block", margin: "1rem" }}
            className="file_input_button"
            onClick={() => {
              this.props.setImage(this.state.image);
              console.log(croppedImageUrl);
              CreateNotifications("success", "Image Selected!", 5000);
              this.props.close();
              if (this.props.src) {
                this.props.src(croppedImageUrl);
              }
            }}
          >
            Select
          </button>
        )}
      </div>
    );
  }
}

export default ImageCropper;
