import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function ImageCard() {
  return (
    <div>
      <SkeletonTheme
        color="rgb(205, 207, 209)"
        highlightColor="rgba(0,0,0,0.20)"
      >
        <Skeleton
          style={{ margin: "5px" }}
          width={"38vh"}
          height={"38vh"}
          duration={2}
          delay={0.5}
        />
      </SkeletonTheme>
    </div>
  );
}
