import React, { useEffect, useState, useRef } from "react";
import MaterialTable from "material-table";

import { deletePlayersFunction } from "../api/Player";

const styles = {
  margin: 40,
  textAlign: "center",
};
function PlayerTable({ players, selectedRow, updateplayer, props }) {
  // useEffect(async () => {
  //   console.log(players);
  // }, []);
  const data = players;

  // console.log(selectedRow);
  const deletePlayer = async (rowData) => {
    selectedRow(rowData);
  };

  const columns = [
    { title: "Name", field: "Name" },
    { title: "Age", field: "Age" },
    { title: "Gender", field: "Gender" },
    { title: "Team", field: "TeamName" },
  ];

  return (
    <MaterialTable
      title=""
      data={data}
      columns={columns}
      actions={[
        {
          icon: "edit",
          tooltip: "edit Player",
          onClick: (event, rowData) => updateplayer(rowData),
        },
        (rowData) => ({
          icon: "delete",
          tooltip: "Delete User",
          onClick: (event, rowData) => deletePlayer(rowData),
          // confirm("You want to delete " + rowData.name),
          //   disabled: rowData.birthYear < 2000,
        }),
      ]}
      options={{ exportButton: true, actionsColumnIndex: -1 }}
    />
  );
}

export default PlayerTable;
