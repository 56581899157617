import React, { useState, useRef, useEffect } from "react";
import Modal from "react-modal";
import { createTeamFunction, updateTeamFunction } from "../api/Teams";
import AppButton from "../AppButton";
import FormInput from "../inputs/FormInput";
import CreateNotifications from "../Notifications";

function UpdateTeam({ setModal, setTeams, update, data }) {
  const [isOpen, setIsOpen] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [location, setLocation] = useState("");
  const [coachName, setCoachName] = useState("");
  const isSubscribed = useRef(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (update) {
      const info = await updateTeamFunction({
        id: data.id,
        Name: teamName,
        Location: location,
        CoachName: coachName,
      });
      console.log(info);
      setModal(false);
      return console.log("updated");
    }
    console.log("creating a team");
    const resp = await createTeamFunction({
      Name: teamName,
      Location: location,
      CoachName: coachName,
    })
      .then((resp) => {
        if (resp.type === "success") {
          if (!isSubscribed.current) return;
          CreateNotifications("success", "successfully created team");
          setModal(false);
        } else throw resp;
      })
      .catch((err) => {
        CreateNotifications(err.type, err.message);
      });
  };
  useEffect(() => {
    if (update) {
      setTeamName(data.Name);
      setLocation(data.Location);
      setCoachName(data.CoachName);
    }
  }, []);

  return (
    <Modal
      isOpen={true}
      style={{
        content: {
          width: "50vw",
          left: "50%",
          right: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        },
      }}
    >
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h3>{update === true ? "Update" : "Create"} Team</h3>
          <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
            <AppButton
              title="X"
              buttoncolor={"red"}
              onClick={() => setModal(false)}
            />
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          {update ? (
            <div>
              <div>
                <label>Team Name</label>
                <FormInput
                  name="Team Name"
                  placeholder="Enter Team Name"
                  defaultValue={teamName}
                  onChange={(e) => {
                    setTeamName(e.target.value);
                  }}
                  required
                />
              </div>
              <div>
                <label>Location</label>
                <FormInput
                  name="Location"
                  placeholder="Enter Location"
                  defaultValue={location}
                  onChange={(e) => {
                    setLocation(e.target.value);
                  }}
                  required
                />
              </div>
              <div>
                <label>Coach Name</label>
                <FormInput
                  name="Coach Name"
                  placeholder="Enter Coach Name"
                  defaultValue={coachName}
                  onChange={(e) => {
                    setCoachName(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
          ) : (
            <div>
              <div>
                <label>Team Name</label>
                <FormInput
                  name="Team Name"
                  placeholder="Enter Team Name"
                  onChange={(e) => {
                    setTeamName(e.target.value);
                  }}
                  required
                />
              </div>
              <div>
                <label>Location</label>
                <FormInput
                  name="Location"
                  placeholder="Enter Location"
                  onChange={(e) => {
                    setLocation(e.target.value);
                  }}
                  required
                />
              </div>
              <div>
                <label>Coach Name</label>
                <FormInput
                  name="Coach Name"
                  placeholder="Enter Coach Name"
                  onChange={(e) => {
                    setCoachName(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
          )}
          <br />
          <div style={{ display: "flex" }}>
            <div>
              <AppButton
                title="Close"
                buttoncolor="red"
                onClick={() => setModal(false)}
              />
            </div>

            <div style={{ marginLeft: "1rem" }}>
              {" "}
              <AppButton type="submit" title={update ? "Update" : "Create"} />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default UpdateTeam;
