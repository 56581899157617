import { API, graphqlOperation } from "aws-amplify";
import { createUser, updateUser, deleteUser } from "../../graphql/mutations";
import { searchByEmail, listUser } from "../../graphql/queries";

let responseData = {
  data: null,
  message: "Api not called",
  type: "error",
};

export const createUserFunction = async (input) => {
  await API.graphql(graphqlOperation(createUser, { input: input }))
    .then((res) => {
      responseData.data = res;
      responseData.message = "Successfully added the Players";
      responseData.type = "success";
    })
    .catch((err) => {
      console.log(err);
      responseData.data = null;
      responseData.message = "Something went wrong while creating the Player";
      responseData.type = "error";
    });

  return responseData;
};

export const getUserByEmail = async (email) => {
  await API.graphql(graphqlOperation(searchByEmail, { email: email }))
    .then((res) => {
      responseData.data = res;
      responseData.message = "Successfully fetched the Players";
      responseData.type = "success";
    })
    .catch((err) => {
      console.log(err);
      responseData.data = null;
      responseData.message = "Something went wrong while creating the Player";
      responseData.type = "error";
    });

  return responseData;
};

export const updateUserFunction = async (input) => {
  await API.graphql(graphqlOperation(updateUser, { input: input }))
    .then((res) => {
      responseData.data = res;
      responseData.message = "Successfully update the Players";
      responseData.type = "success";
    })
    .catch((err) => {
      console.log(err);
      responseData.data = null;
      responseData.message = "Something went wrong while updating the Player";
      responseData.type = "error";
    });

  return responseData;
};
