import React, { useEffect, useRef } from "react";
import ScreenWrapper from "../ScreenWrapper";
import AppButton from "../AppButton";
import TeamCard from "../TeamCard";
import { useState } from "react";
import {
  createTeamFunction,
  deleteTeamFunction,
  updateTeamFunction,
  listTeamsFunction,
  getTeamsFunction,
} from "../api/Teams";
import UpdateTeam from "../modals/UpdateTeam";
import CreateNotifications from "../Notifications";
import ViewTeam from "../modals/ViewTeam";
import AddButton from "../AddButton";

const Team = () => {
  const [isModal, setModal] = useState(false);
  const [teams, setTeams] = useState([]);
  const [update, setUpdate] = useState(false);
  const [isView, setView] = useState(false);
  const [currentItem, setCurrentItem] = useState([]);

  const Modal = (val) => {
    setModal(val);
    if (val == false) {
      setUpdate(false);
      getTeams();
    }
  };
  const viewModal = (val) => {
    setView(val);
  };

  const deleting = async (id) => {
    await deleteTeamFunction(id)
      .then((res) => {
        if (res.type === "success") {
          CreateNotifications(res.type, res.message);
          getTeams();
        } else throw res;
      })
      .catch((err) => {
        CreateNotifications(err.type, err.message);
      });
  };

  const getTeams = async () => {
    const resp = await listTeamsFunction();
    setTeams(resp.data.data.listTeams.items);
    // console.log(resp);
    // console.log(resp.data.data.listTeams);
    // const data = await getTeamsFunction("88d7587b-c0af-4ae9-a30d-bbce569a7677");
    // console.log(data);
  };
  const updating = async () => {
    setUpdate(true);
    Modal(true);
  };
  useEffect(async () => {
    getTeams();
  }, []);

  return (
    <ScreenWrapper>
      {isModal && (
        <UpdateTeam
          setModal={Modal}
          update={update}
          setTeams={teams}
          data={currentItem}
        />
      )}
      {isView && <ViewTeam setView={viewModal} data={currentItem} />}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1rem",
        }}
      >
        <h3>Teams</h3>
        <AddButton
          style={{ width: "250px" }}
          title={"ADD NEW TEAM"}
          onClick={() => {
            setModal(true);
          }}
        />
      </div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {teams.map((team, i) => {
          return (
            <>
              <TeamCard
                key={team.id}
                teams={team}
                updateHandler={updating}
                deleteHandler={deleting}
                setView={setView}
                onClick={setCurrentItem}
              />
            </>
          );
        })}
      </div>
    </ScreenWrapper>
  );
};

export default Team;
