import React, { useEffect, useState, useRef } from "react";
import FormInput from "../../inputs/FormInput";
import ScreenWrapper from "../../ScreenWrapper";
import MD5 from "crypto-js/md5";
import {
  Container,
  Form,
  Image,
  Button,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import {
  createPlayersFunction,
  listPlayersFunction,
  deletePlayersFunction,
  getPlayersFunction,
  updatePlayersFunction,
} from "../../api/Player";
import {
  createTeamFunction,
  deleteTeamFunction,
  updateTeamFunction,
  listTeamsFunction,
  getTeamsFunction,
} from "../../api/Teams";

import AuthAPI from "../../api/Auth";
import CreateNotifications from "../../Notifications";
import Modal from "react-modal";
import ImageCropper from "../../ImageCropper";
import Amplify, { Storage } from "aws-amplify";

function UpdatePlayer(props) {
  const [name, setname] = useState(null);
  const [gender, setgender] = useState(null);
  const [selectedteam, setselectedteam] = useState(null);
  const [selectedteamname, setselectedteamname] = useState(null);
  const [teams, setTeams] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [imgName, setImgName] = useState("");
  const [imgsrc, setimagesrc] = useState("");
  const [age, setAge] = useState(null);
  const [profileImageKey, setprofileImageKey] = useState(null);
  const [profileS3URL, setprofileS3URL] = useState(
    "https://www.pngitem.com/pimgs/m/35-350426_profile-icon-png-default-profile-picture-png-transparent.png"
  );
  useEffect(async () => {
    // console.log();
    getTeams();
    getPlayer(props.match.params.id);
    // const resp = await listPlayersFunction();
    // console.log(resp);
  }, []);

  const getPlayer = async (id) => {
    const resp = await getPlayersFunction(id);
    const obj = resp.data.data.getPlayers;
    setname(obj.Name);
    setgender(obj.Gender);
    setAge(obj.Age);
    setselectedteam(obj.teamID);
    setselectedteamname(obj.teamname);
    setprofileImageKey(obj.ProfilePicture);
    if (obj.ProfilePicture != null) {
      const signedURL = await Storage.get(obj.ProfilePicture, {
        expires: 60000,
      });
      setprofileS3URL(signedURL);
    }

    console.log(obj);
  };

  const updatePlayer = async (teamname) => {
    await imageUpload();
    console.log(profileImageKey);
    await updatePlayersFunction({
      id: props.match.params.id,
      Name: name,
      Gender: gender,
      Age: age,
      TeamName: teamname,
      teamID: selectedteam,
      ProfilePicture: props.match.params.id,
    })
      .then((resp) => {
        console.log(resp);
        if (resp.type === "success") {
          CreateNotifications("success", "Player successfully updated");
          getPlayer(props.match.params.id);
          // props.history.push("/admin/player");
        }
      })
      .catch((err) => {
        console.log(err);
        CreateNotifications(err.type, err.message);
      });
  };
  const getTeams = async () => {
    const resp = await listTeamsFunction();
    setTeams(resp.data.data.listTeams.items);

    // console.log(resp);
    // console.log(resp.data.data.listTeams);
    // const data = await getTeamsFunction("88d7587b-c0af-4ae9-a30d-bbce569a7677");
    // console.log(data);
  };
  const onformsubmit = async (e) => {
    e.preventDefault();
    var obj = teams.find((o) => o.id == selectedteam);
    var teamname = obj.Name;
    // await setselectedteamname(name);
    // console.log(selectedteamname, name);
    updatePlayer(teamname);
    // console.log(name, age, gender, selectedteam, selectedteamname);
  };

  const imageUpload = async () => {
    if (!profileImageKey && imageUrl) {
      // var current_email = resp.data.attributes.email;
      var filename = props.match.params.id;
      setprofileImageKey(filename);
      const result = await Storage.put(filename, imageUrl);
      console.log(result);
    } else if ((imageUrl, profileImageKey)) {
      const result = await Storage.put(profileImageKey, imageUrl);
      console.log(result);
    } else {
      // const result = await Storage.put(profileImageKey, imageUrl);
      console.log("nothing to update in image");
    }

    // if (imageUrl) {
    //   const result = await Storage.put(filename, imageUrl);
    //   console.log(result);
    // }
  };

  const imageName = (image) => {
    console.log(imageUrl);
    setImgName(image);
  };
  return (
    <ScreenWrapper>
      <Container>
        <Row></Row>
        <Row>
          <Col md={2}> </Col>
          <Col md={8}>
            <Card bg={"light"} style={{ width: "100%" }}>
              <Card.Body style={{ textAlign: "center" }}>
                <h5>UPDATE PLAYER</h5>
              </Card.Body>
            </Card>
            <Row>
              <Col xs={12} md={12}>
                <div className="text-center">
                  <Image
                    src={imgsrc == "" ? profileS3URL : imgsrc}
                    thumbnail
                    style={{ objectFit: "cover", marginTop: "20px" }}
                    height="200"
                    width="200"
                    // alt={imageName}
                  />
                </div>
                <div className="text-center" style={{ margin: "10px" }}>
                  <i
                    class="fa fa-pencil fa-lg"
                    style={{ marginRight: "20px" }}
                    onClick={() => setIsOpen(true)}
                  ></i>
                  <i
                    class="fa fa-trash fa-lg"
                    onClick={() => {
                      setprofileImageKey(null);
                      setprofileS3URL(
                        "https://www.pngitem.com/pimgs/m/35-350426_profile-icon-png-default-profile-picture-png-transparent.png"
                      );
                    }}
                  ></i>
                </div>
              </Col>
            </Row>
            <Modal isOpen={isOpen}>
              <ImageCropper
                setImage={setImageUrl}
                imageName={imageName}
                aspectRatio={1 / 1}
                close={() => setIsOpen(false)}
                src={setimagesrc}
              />
            </Modal>

            <Form style={{ margin: "30px" }} onSubmit={(e) => onformsubmit(e)}>
              {/* <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Default file input example</Form.Label>
                <Form.Control type="file" />
              </Form.Group> */}
              {/* <p>{imageUrl.toString()}</p> */}
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  value={name}
                  onChange={(e) => {
                    setname(e.target.value);
                  }}
                  type="text"
                  placeholder="Enter your Full Name"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicAge">
                <Form.Label>Age</Form.Label>
                <Form.Control
                  value={age}
                  onChange={(e) => {
                    setAge(e.target.value);
                  }}
                  type="number"
                  placeholder="Enter your Age"
                />
                <Form.Text className="text-muted">Enter only numbers</Form.Text>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Your Gender</Form.Label>
                <Form.Select
                  value={gender}
                  onChange={(e) => {
                    setgender(e.target.value);
                  }}
                  aria-label="Gender"
                  S
                >
                  <option>Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Choose Team</Form.Label>
                <Form.Select
                  value={selectedteam}
                  onChange={(e) => {
                    setselectedteam(e.target.value);
                  }}
                  aria-label="Chose Team"
                >
                  <option>Open this select menu</option>
                  {teams.map((item, i) => (
                    <option key={i} value={item.id}>
                      {item.Name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Text className="text-muted">
                  Can't Find Team ?<a href="/admin/team">Create Team</a>{" "}
                </Form.Text>
              </Form.Group>
              <Button
                variant="danger"
                onClick={() => props.history.push("/admin/player")}
                className="mx-3"
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Update
              </Button>
            </Form>
          </Col>
          <Col md={{ offset: 2 }}></Col>
        </Row>
      </Container>
    </ScreenWrapper>
  );
}

export default UpdatePlayer;
