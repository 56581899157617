import { IoIosApps } from "react-icons/io";
import {
  RiLogoutBoxRLine,
  RiHeadphoneFill,
  RiVideoUploadFill,
  RiCalendarEventFill,
  RiTeamFill,
} from "react-icons/ri";
import { AiFillHome, AiOutlineCluster } from "react-icons/ai";
import { NavLink, useHistory } from "react-router-dom";
import Logo from "../assets/images/logo.png";

const Drawer = () => {
  const history = useHistory();
  return (
    <section className="left_sidebar">
      <div className="sidebar__header">
        <div onClick={() => history.push("/")} className="header__logo">
          <img src={Logo} alt="logo" />
        </div>
        <div className="header__title">
          <h3>757 Next Level</h3>
        </div>
      </div>
      <nav className="sidebar__navlinks">
        <NavLink className="links" to="/admin">
          <IoIosApps size={20} />
          <h3>Dashboard</h3>
        </NavLink>
        <NavLink
          className="links"
          activeClassName="active__navlink"
          to="/admin/collections"
        >
          <AiOutlineCluster size={20} />
          <h3>Collection Faces</h3>
        </NavLink>
        <NavLink
          className="links"
          activeClassName="active__navlink"
          to="/admin/home"
        >
          <AiFillHome size={20} />
          <h3>Home</h3>
        </NavLink>
        <NavLink
          className="links"
          activeClassName="active__navlink"
          to="/admin/registrations"
        >
          <RiCalendarEventFill size={20} />
          <h3>Registrations</h3>
        </NavLink>

        <NavLink
          className="links"
          activeClassName="active__navlink"
          to="/admin/team"
        >
          <RiTeamFill size={20} />
          <h3>Teams</h3>
        </NavLink>
        <NavLink
          className="links"
          activeClassName="active__navlink"
          to="/admin/player"
        >
          <RiTeamFill size={20} />
          <h3>Players</h3>
        </NavLink>

        <NavLink
          className="links"
          activeClassName="active__navlink"
          to="/admin/videos"
        >
          <RiVideoUploadFill size={20} />
          <h3>Video</h3>
        </NavLink>

        <NavLink
          className="links"
          activeClassName="active__navlink"
          to="/admin/podcasts"
        >
          <RiHeadphoneFill size={20} />
          <h3>Podcasts</h3>
        </NavLink>

        <NavLink
          className="links"
          activeClassName="active__navlink"
          to="/logout"
        >
          <RiLogoutBoxRLine size={20} />
          <h3>Sign Out</h3>
        </NavLink>
      </nav>
    </section>
  );
};

export default Drawer;
