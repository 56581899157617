import Amplify, { API, graphqlOperation } from 'aws-amplify';
import awsmobile from '../../aws-exports';
import {
  createRegistrations,
  deleteRegistrations,
  updateRegistrations,
} from '../../graphql/mutations';
import { getRegistrations, listRegistrations } from '../../graphql/queries';

Amplify.configure(awsmobile);

let responseData = {
  type: 'error',
  message: 'API Not Called',
  data: null,
};

export const CreateRegistrations = async (input) => {
  await API.graphql(graphqlOperation(createRegistrations, { input: input }))
    .then((res) => {
      responseData.data = res;
      responseData.message = 'Successfully created the new registration';
      responseData.type = 'success';
    })
    .catch((err) => {
      responseData.data = err;
      responseData.message =
        'Something went wrong while creating the registration';
      responseData.type = 'error';
    });

  return responseData;
};

export const UpdateRegistrations = async (input) => {
  await API.graphql(graphqlOperation(updateRegistrations, { input: input }))
    .then((res) => {
      responseData.data = res;
      responseData.message = 'Successfully updated the new registration';
      responseData.type = 'success';
    })
    .catch((err) => {
      responseData.data = err;
      responseData.message =
        'Something went wrong while updating the registration';
      responseData.type = 'error';
    });

  return responseData;
};

export const DeleteRegistrations = async (id) => {
  await API.graphql(
    graphqlOperation(deleteRegistrations, { input: { id: id } })
  )
    .then((res) => {
      responseData.data = res;
      responseData.message = 'Successfully deleted the  registration';
      responseData.type = 'success';
    })
    .catch((err) => {
      responseData.data = err;
      responseData.message =
        'Something went wrong while deleting the registration';
      responseData.type = 'error';
    });

  return responseData;
};

export const GetRegistrationById = async (id) => {
  await API.graphql(graphqlOperation(getRegistrations, { id: id }))
    .then((res) => {
      responseData.data = res;
      responseData.message = 'Successfully fetched the  registration';
      responseData.type = 'success';
    })
    .catch((err) => {
      responseData.data = err;
      responseData.message =
        'Something went wrong while fetching the registration';
      responseData.type = 'error';
    });

  return responseData;
};

export const ListRegistration = async () => {
  await API.graphql(graphqlOperation(listRegistrations))
    .then((res) => {
      responseData.data = res;
      responseData.message = 'Successfully fetched the  registration';
      responseData.type = 'success';
    })
    .catch((err) => {
      responseData.data = err;
      responseData.message =
        'Something went wrong while fetching the registration';
      responseData.type = 'error';
    });

  return responseData;
};
