import { API, graphqlOperation } from "aws-amplify";
import { createLogs, updateLogs, deleteLogs } from "../../graphql/mutations";
import { getLogs, listLogs } from "../../graphql/queries";

let responseData = {
  data: null,
  message: "Api not called",
  type: "error",
};

export const createLogsFunction = async (input) => {
  await API.graphql(graphqlOperation(createLogs, { input: input }))
    .then((res) => {
      responseData.data = res;
      responseData.message = "Successfully added the new log.";
      responseData.type = "success";
    })
    .catch((err) => {
      responseData.data = null;
      responseData.message = "Something went wrong while adding the log";
      responseData.type = "error";
    });

  return responseData;
};

export const updateLogsFunction = async (input) => {
  await API.graphql(graphqlOperation(updateLogs, { input: input }))
    .then((res) => {
      responseData.data = res;
      responseData.message = "Successfully updated the log";
      responseData.type = "success";
    })
    .catch((err) => {
      responseData.data = null;
      responseData.message = "Something went wrong while updating the log";
      responseData.type = "error";
    });

  return responseData;
};

export const deleteLogsFunction = async (id) => {
  await API.graphql(graphqlOperation(deleteLogs, { input: { id: id } }))
    .then((res) => {
      responseData.data = res;
      responseData.message = "Successfully deleted the log";
      responseData.type = "success";
    })
    .catch((err) => {
      responseData.data = null;
      responseData.message = "Something went wrong while deleting the log";
      responseData.type = "error";
    });

  return responseData;
};

export const listLatestLogsFunction = async () => {
  await API.graphql(graphqlOperation(listLogs))
    .then((res) => {
      responseData.data = res;
      responseData.message = "Successfully fetched the latest logs";
      responseData.type = "success";
    })
    .catch((err) => {
      responseData.data = null;
      responseData.message = "Something went wrong while fetching the logs";
      responseData.type = "error";
    });

  return responseData;
};

export const getLatestLogsFunction = async (id) => {
  await API.graphql(graphqlOperation(getLogs, { id: id }))
    .then((res) => {
      responseData.data = res;
      responseData.message = "Successfully fetched the latests logs";
      responseData.type = "success";
    })
    .catch((err) => {
      responseData.data = null;
      responseData.message =
        "Something went wrong while fetching the latest logs";
      responseData.type = "error";
    });

  return responseData;
};
