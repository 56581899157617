import { API, graphqlOperation } from "aws-amplify";
import { createNews, deleteNews, updateNews } from "../../graphql/mutations";
import { getNews, listNews } from "../../graphql/queries";

let responseData = {
  data: null,
  message: "Api not called",
  type: "error",
};

export const createLatestContent = async (input) => {
  await API.graphql(graphqlOperation(createNews, { input: input }))
    .then((res) => {
      responseData.data = res;
      responseData.message = "Successfully added the content.";
      responseData.type = "success";
    })
    .catch((err) => {
      responseData.data = null;
      responseData.message = "Something went wrong while adding the content";
      responseData.type = "error";
    });

  return responseData;
};

export const updateLatestContent = async (input) => {
  await API.graphql(graphqlOperation(updateNews, { input: input }))
    .then((res) => {
      responseData.data = res;
      responseData.message = "Successfully updated the content";
      responseData.type = "success";
    })
    .catch((err) => {
      responseData.data = null;
      responseData.message = "Something went wrong while updating the content";
      responseData.type = "error";
    });

  return responseData;
};

export const deleteLatestContent = async (id) => {
  await API.graphql(graphqlOperation(deleteNews, { input: { id: id } }))
    .then((res) => {
      responseData.data = res;
      responseData.message = "Successfully deleted the content";
      responseData.type = "success";
    })
    .catch((err) => {
      responseData.data = null;
      responseData.message = "Something went wrong while deleting the content";
      responseData.type = "error";
    });

  return responseData;
};

export const listLatestContent = async () => {
  await API.graphql(graphqlOperation(listNews))
    .then((res) => {
      responseData.data = res;
      responseData.message = "Successfully fetched the latest contents";
      responseData.type = "success";
    })
    .catch((err) => {
      responseData.data = null;
      responseData.message = "Something went wrong while fetching the content";
      responseData.type = "error";
    });

  return responseData;
};

export const getLatestContent = async (id) => {
  await API.graphql(graphqlOperation(getNews, { id: id }))
    .then((res) => {
      responseData.data = res;
      responseData.message = "Successfully fetched the latests content";
      responseData.type = "success";
    })
    .catch((err) => {
      responseData.data = null;
      responseData.message =
        "Something went wrong while fetching the latest content";
      responseData.type = "error";
    });

  return responseData;
};

export const latestFilteredContent = async (filter) => {
  await API.graphql(
    graphqlOperation(listNews, { filter: { type: { eq: filter } } })
  )
    .then((res) => {
      responseData.data = res;
      responseData.message = "Successfully fetched the latests content";
      responseData.type = "success";
    })
    .catch((err) => {
      responseData.data = null;
      responseData.message =
        "Something went wrong while fetching the latest content";
      responseData.type = "error";
    });

  return responseData;
};
