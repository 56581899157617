import Auth from "@aws-amplify/auth";
import React, { useState } from "react";
import { Container, Row, Col, Button, Form, Image } from "react-bootstrap";
import { useHistory } from "react-router";
import AuthAPI from "../../api/Auth";
import storage from "../../api/storage";
import CreateNotifications from "../../Notifications";
import Logo from "../../../assets/images/logo.png";

function PlayerSignup(props) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [name, setName] = useState("");
  const history = useHistory();

  const handleSignUp = async (e) => {
    e.preventDefault();

    await Auth.signUp({
      username,
      password,
      attributes: {
        name,
      },
    })
      .then(async (user) => {
        console.log(user);
        history.push({
          pathname: "/player/verification",
          state: { username: username, name: name },
        });
        CreateNotifications(
          "success",
          "Verification Code has been sent to your email"
        );
      })
      .catch((err) => {
        console.log(err);
        // alert(err.message);
        CreateNotifications("error", err.message);
      });

    console.log("form submitted");
  };
  return (
    <div>
      <Container>
        <Row className="justify-content-md-center" style={{ margin: "10px" }}>
          <Col xs lg="6">
            <Image
              src={Logo}
              thumbnail
              style={{
                marginBottom: "20px",
                alignItems: "center",
                backgroundColor: "darkgrey",
                height: "100px",
              }}
            />
            <h3 style={{ marginBottom: "30px" }}>Player's SignUp</h3>
            <Form onSubmit={handleSignUp}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Your Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <p>
                Already have a account ?{" "}
                <span style={{ color: "blue" }}>
                  {" "}
                  <a href="/player"> Sign In </a>{" "}
                </span>{" "}
              </p>
              <Button disabled={loader} variant="primary" type="submit">
                SignUp
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PlayerSignup;
