import React, { useRef, useState, useEffect } from "react";
import videojs from "video.js";

import "../../../node_modules/video.js/dist/video-js.css";
import "videojs-contrib-quality-levels";
import "videojs-http-source-selector";

export const VideoPlayer = (props) => {
  const videoPlayerRef = useRef(null); // Instead of ID
  const [currentTime, setCurrentTime] = useState(null);
  const videoSrc =
    "https://d1a2nakvpdho11.cloudfront.net/02175ca2-952a-471a-8b7c-2158cdab3f28/AppleHLS1/10000000_337371274518413_7072524752984607757_n.m3u8";
  const videoJSOptions = {
    autoplay: true,
    plugins: {
      httpSourceSelector: {
        default: "1080",
      },
    },
    controls: true,
    userActions: { hotkeys: true },
    playbackRates: [0.25, 0.5, 1, 1.5, 2],
  };

  useEffect(() => {
    if (videoPlayerRef) {
      const player = videojs(videoPlayerRef.current, videoJSOptions, () => {
        let qualityLevels = player.qualityLevels();
        player.httpSourceSelector();
        console.log(qualityLevels);
        player.src(videoSrc);
        player.on("ended", () => {
          console.log("ended");
        });
        player.on("timeupdate", () => {
          setCurrentTime(player.currentTime());
        });
        console.log("Player Ready");
      });
    }

    return () => {};
  }, []);

  return (
    <div>
      <video ref={videoPlayerRef} className="video-js vjs-big-play-centered" />
      <span>Current Time: {currentTime}</span>
      {/* <GlobalStyle /> */}
    </div>
  );
};

export default VideoPlayer;
