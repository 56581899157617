import React, { useEffect, useRef } from 'react';
import ReactModal from 'react-modal';

function ConfirmDelete({ deleteAction, setToDelete, show }) {
  const ref = useRef(null);
  useEffect(() => {
    const handleClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setToDelete();
      }
    };
    document.addEventListener('mousedown', handleClick);

    return () => document.removeEventListener('mousedown', null);
  });
  return (
    <ReactModal className="confirm__delete" isOpen={show}>
      <section ref={ref} className="confirm">
        <div className="header">
          <h2>Do you want to delete?</h2>
        </div>
        <div className="form__btn_container">
          <button
            style={{ marginRight: '1rem' }}
            className="form_submit_button cancel"
            onClick={deleteAction}
          >
            Delete
          </button>
          <button className="form_submit_button" onClick={setToDelete}>
            Cancel
          </button>
        </div>
      </section>
    </ReactModal>
  );
}

export default ConfirmDelete;
