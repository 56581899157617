import Amplify, { Auth } from "aws-amplify";
import awsmobile from "../../aws-exports";
import CreateNotifications from "../Notifications";

Amplify.configure(awsmobile);

let responseData = {
  type: "error",
  message: "API Not Called",
  data: null,
};
const signUp = async (data) => {
  await Auth.signOut();
  await Auth.signUp(data)
    .then((response) => {
      // console.log(response);
      responseData.data = response;
      responseData.message = "Verification Code Sent";
      responseData.type = "success";
    })
    .catch((err) => {
      responseData.message = err.message;
    });
  return responseData;
};

const signIn = async (username, password) => {
  await Auth.signOut();
  await Auth.signIn(username, password)
    .then((response) => {
      responseData.data = response;
      responseData.message = "Login Successful";
      responseData.type = "success";
    })
    .catch((err) => {
      CreateNotifications("error", err.message);
      responseData.message = err.message;
      responseData.type = "error";
    });
  return responseData;
};

const currentCredentials = async () => {
  await Auth.currentCredentials()
    .then((response) => {
      responseData.data = response;
      responseData.message = "Credentials of current user fetched successfully";
      responseData.type = "success";
    })
    .catch((err) => {
      responseData.message = err.message;
    });
  return responseData;
};

const refreshToken = async () => {
  await Auth.currentSession()
    .then((data) => console.log("token", data))
    .catch((err) => CreateNotifications("error", "Cannot find the token"));
};

const currentSession = async () => {
  await Auth.currentSession()
    .then((response) => {
      responseData.data = response;
      responseData.message = "Credentials of current user fetched successfully";
      responseData.type = "success";
    })
    .catch((err) => {
      responseData.message = err.message;
    });
  return responseData;
};

const currentAuthenticatedUser = async () => {
  await Auth.currentAuthenticatedUser()
    .then((response) => {
      responseData.data = response;
      responseData.message = "Credentials of current user fetched successfully";
      responseData.type = "success";
    })
    .catch((err) => {
      console.log(err);
      responseData.message = err.message;
    });
  return responseData;
};

const signOut = async () => {
  await Auth.signOut()
    .then((response) => {
      console.log(response);
      responseData.data = response;
      responseData.message = "Sign out Successful";
      responseData.type = "success";
    })
    .catch((err) => {
      responseData.message = err.message;
      responseData.type = "error";
    });
  return responseData;
};

const confirmSignUp = async (username, code) => {
  await Auth.confirmSignUp(username, code)
    .then((response) => {
      responseData.data = response;
      responseData.message = "Sign up Successful";
      responseData.type = "success";
    })
    .catch((err) => {
      responseData.message = err.message;
      responseData.type = "error";
    });
  return responseData;
};

const resendConfirmationCode = async (username) => {
  await Auth.resendSignUp(username)
    .then((response) => {
      responseData.data = response;
      responseData.message = "code resent successfull";
      responseData.type = "success";
    })
    .catch((err) => {
      responseData.message = err.message;
      responseData.type = "error";
    });
  return responseData;
};

const resetPassword = async (username) => {
  await Auth.forgotPassword(username)
    .then((response) => {
      responseData.data = response;
      responseData.message = "Verification Code Sent to your email";
      responseData.type = "success";
    })
    .catch((err) => {
      responseData.message = err.message;
      responseData.type = "error";
    });

  return responseData;
};

const resetPasswordSubmit = async (username, code, newPassword) => {
  await Auth.forgotPasswordSubmit(username, code, newPassword)
    .then((response) => {
      responseData.message = "Password Reset Successfull!";
      responseData.type = "success";
    })
    .catch((err) => {
      responseData.message = err.message;
      responseData.type = "error";
    });

  return responseData;
};

const AuthAPI = {
  signUp,
  signIn,
  signOut,
  currentCredentials,
  confirmSignUp,
  refreshToken,
  resendConfirmationCode,
  currentSession,
  resetPassword,
  resetPasswordSubmit,
  currentAuthenticatedUser,
};

export default AuthAPI;
