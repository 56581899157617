import { Storage } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import Modal from 'react-modal';
import { CreateRegistrations, UpdateRegistrations } from '../api/registrations';
import AppButton from '../AppButton';
import ImageCropper from '../ImageCropper';
import FileInput from '../inputs/FileInput';
import FormInput from '../inputs/FormInput';
import FormTextArea from '../inputs/FormTextArea';
import CreateNotifications from '../Notifications';

const DateFormater = (date) => {
  if (date === null) return '';
  const today = new Date(date);

  return `${today.getFullYear()}-${
    today.getMonth() <= 9 ? `0${today.getMonth()}` : today.getMonth()
  }-${today.getDate() <= 9 ? `0${today.getDate()}` : today.getDate()}`;
};

const INITIAL_DATA = {
  title: '',
  description: '',
  eventBanner: null,
  startdate: null,
  enddate: null,
  attachments: {
    files: [
      { name: '', file: null },
      { name: '', file: null },
      { name: '', file: null },
      { name: '', file: null },
      { name: '', file: null },
    ],
  },

  contact: { name: '', phone: '', email: '' },
};

function UpdateRegistration({ setModalOpen, toUpdate = null, updateAction }) {
  const [imageUrl, setImageUrl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [input, setInput] = useState(INITIAL_DATA);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (toUpdate) {
      setInput({
        ...toUpdate,
        attachments: JSON.parse(toUpdate.attachments),
        contact: JSON.parse(toUpdate.contact),
      });

      const attachments = JSON.parse(toUpdate.attachments);
      attachments.files.forEach(async (item) => {
        if (item.file) {
          const file = await Storage.get(item.name);
          setFiles((files) => [...files, file]);
        } else {
          setFiles((files) => [...files, null]);
        }
      });
    }
  }, [toUpdate]);

  const handleSubmit = async () => {
    let toProceed = true;

    if (input.title.length === 0) {
      toProceed = false;
      CreateNotifications('warning', 'Enter title');
      return null;
    }
    input.attachments.files.forEach((item) => {
      if (item.name && !item.file) {
        CreateNotifications('warning', 'Select the attachment file');
        toProceed = false;
        return null;
      }
      if (item.file && !item.name) {
        CreateNotifications('warning', 'Enter the attachment file name');
        toProceed = false;
        return null;
      }
    });

    if (toUpdate === null) {
      const newInput = {
        ...input,
        eventBanner: `${input.title}-eventBanner.png`,
        attachments: JSON.stringify(input.attachments),
        contact: JSON.stringify(input.contact),
      };

      toProceed &&
        imageUrl &&
        (await Storage.put(`${input.title}-eventBanner.png`, imageUrl).catch(
          (err) => {
            CreateNotifications(
              'error',
              'Something went wrong while uploading the event banner'
            );
          }
        ));

      toProceed &&
        input.attachments.files.forEach(async (item) => {
          item.file &&
            (await Storage.put(`${item.name}-${input.title}`, item.file)
              .then((res) => {
                CreateNotifications(
                  'success',
                  'Successfully uploaded the attachment'
                );
              })
              .catch((err) => {
                CreateNotifications(
                  'error',
                  'Something went wrong while uploading the attachment'
                );
              }));
        });

      toProceed &&
        CreateNotifications('info', 'Creating new registration. Please wait.');

      toProceed &&
        (await CreateRegistrations(newInput)
          .then((res) => {
            if (res.type === 'success') {
              CreateNotifications(res.type, res.message);
            } else throw res;
          })
          .catch((err) => {
            CreateNotifications(err.type, err.message);
          }));

      toProceed && setModalOpen(false);
      toProceed && updateAction();
    } else {
      const newInput = {
        ...input,
        id: toUpdate.id,
        eventBanner: `${input.title}-eventBanner.png`,
        attachments: JSON.stringify(input.attachments),
        contact: JSON.stringify(input.contact),
      };

      delete newInput['createdAt'];
      delete newInput['updatedAt'];

      imageUrl &&
        toProceed &&
        (await Storage.put(`${input.title}-eventBanner.png`, imageUrl).catch(
          (err) => {
            CreateNotifications(
              'error',
              'Something went wrong while uploading the event banner'
            );
          }
        ));

      await input.attachments.files.forEach(async (item) => {
        toProceed &&
          item.file &&
          (await Storage.put(item.name, item.file).catch((err) => {
            CreateNotifications(
              'error',
              'Something went wrong while uploding the attachments'
            );
            return;
          }));
      });

      toProceed &&
        CreateNotifications('info', 'Updating the registration. Please wait.');

      toProceed &&
        (await UpdateRegistrations(newInput)
          .then((res) => {
            CreateNotifications(res.type, res.message);
          })
          .catch((err) => {
            CreateNotifications(err.type, err.message);
          }));

      toProceed && setModalOpen(false);
      toProceed && updateAction();
    }
  };

  const handleChange = (e) => {
    if (e.target.name.includes('attachments')) {
      const target = e.target.name.split('/')[1];
      const index = Number(e.target.name.split('/')[2]);
      if (target === 'name') {
        setInput((input) => {
          return {
            ...input,
            attachments: {
              files: [
                ...input.attachments.files.slice(0, index),
                {
                  name: e.target.value,
                  file: input.attachments.files[index].file,
                },
                ...input.attachments.files.slice(index + 1),
              ],
            },
          };
        });
      } else {
        setInput((input) => {
          return {
            ...input,
            attachments: {
              files: [
                ...input.attachments.files.slice(0, index),
                {
                  name: input.attachments.files[index].name,
                  file: e.target.files[0],
                },
                ...input.attachments.files.slice(index + 1),
              ],
            },
          };
        });
      }
    } else if (e.target.name.includes('contact')) {
      const target = e.target.name.split('/')[1];
      setInput((input) => {
        return {
          ...input,
          contact: { ...input.contact, [target]: e.target.value },
        };
      });
    } else {
      setInput((input) => {
        return { ...input, [e.target.name]: e.target.value };
      });
    }
  };

  return (
    <Modal isOpen={true}>
      <div
        style={{
          flex: 1,
          display: 'flex',
          position: 'absolute',
          top: 0,
          width: '90%',
          margin: '1rem',
        }}
      >
        <div style={{ flex: 1, display: 'flex' }}>
          <h5 style={{ fontSize: '20px', fontWeight: '400' }}>
            {toUpdate ? 'Update' : 'Create'} Registration
          </h5>
        </div>
        <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
          <AppButton
            title="X"
            buttoncolor={'red'}
            onClick={() => setModalOpen(false)}
          />
        </div>
      </div>
      <div
        className="no-scroll-bar"
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'scroll',
          height: '85%',
          marginTop: '3%',
        }}
      >
        <br />
        <table>
          <colgroup>
            <col span="1" style={{ width: '20%' }} />
            <col span="1" style={{ width: '40%' }} />
            <col span="1" style={{ width: '40%' }} />
          </colgroup>
          <tr>
            <td>
              <p>Title of the Event : </p>
            </td>
            <td>
              <FormInput
                name="title"
                value={input.title}
                onChange={(e) => handleChange(e)}
                placeholder="Title of the Event"
              />
            </td>
          </tr>
          <tr>
            <td>
              <p>Description of the Event</p>
            </td>
            <td>
              <FormTextArea
                name="description"
                value={input.description}
                onChange={(e) => handleChange(e)}
                placeholder="Description of the Event"
              />
            </td>
          </tr>
          <tr>
            <td>
              <p>Banner of the Event</p>
            </td>
            <td>
              {imageUrl ? (
                <>
                  <p className="success_line">Image selected</p>
                  <AppButton
                    title="Upload"
                    buttoncolor="green"
                    onClick={() => setIsOpen(true)}
                  />
                </>
              ) : (
                <AppButton
                  title="Select Image"
                  buttoncolor="blue"
                  onClick={() => setIsOpen(true)}
                />
              )}

              {input.eventBanner && !imageUrl ? (
                <AppButton
                  onClick={() => {}}
                  buttoncolor="blue"
                  title="View Image"
                />
              ) : null}

              <Modal isOpen={isOpen}>
                <ImageCropper
                  setImage={setImageUrl}
                  close={() => setIsOpen(false)}
                />
              </Modal>
            </td>
          </tr>
          <tr>
            <td>
              <p>Start date of the Event</p>
            </td>
            <td>
              {' '}
              <FormInput
                name="startdate"
                value={DateFormater(input.startdate)}
                onChange={(e) => handleChange(e)}
                type="date"
                placeholder="Start Date"
                required
              />
            </td>
          </tr>
          <tr>
            <td>
              <p>End Date of the Event</p>
            </td>
            <td>
              {' '}
              <FormInput
                name="enddate"
                value={DateFormater(input.enddate)}
                onChange={(e) => handleChange(e)}
                type="date"
                placeholder="End Date"
                required
              />
            </td>
          </tr>
          <tr>
            <td>
              <p>Attachments</p>
            </td>
            <td>
              <table>
                <colgroup>
                  <col span="1" style={{ width: '60%' }} />
                  <col span="1" style={{ width: '40%' }} />
                </colgroup>
                <tr>
                  <td>
                    <FormInput
                      name="attachments/name/0"
                      type="text"
                      placeholder="Name of the Attachment"
                      value={input.attachments.files[0].name}
                      onChange={(e) => handleChange(e)}
                    />
                  </td>
                  <td>
                    <FileInput
                      name="attachments/file/0"
                      onChange={handleChange}
                      type="file"
                    />
                  </td>
                  <td>
                    {input.attachments.files[0].file && (
                      <IoIosCheckmarkCircleOutline
                        style={{ marginLeft: '1rem' }}
                        size={20}
                        color="green"
                      />
                    )}
                  </td>
                  <td>
                    {files[4] ? (
                      <a href={files[4]} download>
                        <AppButton title="Download" buttoncolor="green" />
                      </a>
                    ) : input.attachments.files[0].file ? (
                      <a href={input.attachments.files[0].file} download>
                        <AppButton title="Download" buttoncolor="green" />
                      </a>
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <FormInput
                      name="attachments/name/1"
                      type="text"
                      placeholder="Name of the Attachment"
                      value={input.attachments.files[1].name}
                      onChange={(e) => handleChange(e)}
                    />
                  </td>
                  <td>
                    <FileInput
                      onChange={(e) => handleChange(e)}
                      name="attachments/file/1"
                      type="file"
                    />
                  </td>
                  <td>
                    {input.attachments.files[1].file && (
                      <IoIosCheckmarkCircleOutline
                        style={{ marginLeft: '1rem' }}
                        size={20}
                        color="green"
                      />
                    )}
                  </td>
                  <td>
                    {files[3] ? (
                      <a href={files[3]} download>
                        <AppButton title="Download" buttoncolor="green" />
                      </a>
                    ) : input.attachments.files[1].file ? (
                      <a href={input.attachments.files[1].file} download>
                        <AppButton title="Download" buttoncolor="green" />
                      </a>
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <FormInput
                      name="attachments/name/2"
                      type="text"
                      placeholder="Name of the Attachment"
                      value={input.attachments.files[2].name}
                      onChange={(e) => handleChange(e)}
                    />
                  </td>
                  <td>
                    <FileInput
                      onChange={(e) => handleChange(e)}
                      name="attachments/file/2"
                      type="file"
                    />
                  </td>
                  <td>
                    {input.attachments.files[2].file && (
                      <IoIosCheckmarkCircleOutline
                        style={{ marginLeft: '1rem' }}
                        size={20}
                        color="green"
                      />
                    )}
                  </td>
                  <td>
                    {files[2] ? (
                      <a href={files[2]} download>
                        <AppButton title="Download" buttoncolor="green" />
                      </a>
                    ) : input.attachments.files[2].file ? (
                      <a href={input.attachments.files[2].file} download>
                        <AppButton title="Download" buttoncolor="green" />
                      </a>
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <FormInput
                      name="attachments/name/3"
                      type="text"
                      placeholder="Name of the Attachment"
                      value={input.attachments.files[3].name}
                      onChange={(e) => handleChange(e)}
                    />
                  </td>
                  <td>
                    <FileInput
                      onChange={(e) => handleChange(e)}
                      name="attachments/file/3"
                      type="file"
                    />
                  </td>
                  <td>
                    {input.attachments.files[3].file && (
                      <IoIosCheckmarkCircleOutline
                        style={{ marginLeft: '1rem' }}
                        size={20}
                        color="green"
                      />
                    )}
                  </td>
                  <td>
                    {files[1] ? (
                      <a href={files[1]} download>
                        <AppButton title="Download" buttoncolor="green" />
                      </a>
                    ) : input.attachments.files[3].file ? (
                      <a href={input.attachments.files[3].file} download>
                        <AppButton title="Download" buttoncolor="green" />
                      </a>
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <FormInput
                      name="attachments/name/4"
                      type="text"
                      placeholder="Name of the Attachment"
                      value={input.attachments.files[4].name}
                      onChange={(e) => handleChange(e)}
                    />
                  </td>
                  <td>
                    <FileInput
                      onChange={(e) => handleChange(e)}
                      name="attachments/file/4"
                      type="file"
                    />
                  </td>
                  <td>
                    {input.attachments.files[4].file && (
                      <IoIosCheckmarkCircleOutline
                        style={{ marginLeft: '1rem' }}
                        size={20}
                        color="green"
                      />
                    )}
                  </td>
                  <td>
                    {files[0] ? (
                      <a href={files[0]} download>
                        <AppButton title="Download" buttoncolor="green" />
                      </a>
                    ) : input.attachments.files[4].file ? (
                      <a href={input.attachments.files[4].file} download>
                        <AppButton title="Download" buttoncolor="green" />
                      </a>
                    ) : null}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <br />
              <p> Contact Details ( SPOC of the Event)</p>
            </td>
          </tr>
          <tr>
            <td>
              <p> Name</p>
            </td>
            <td>
              <FormInput
                name="contact/name"
                value={input.contact.name}
                onChange={(e) => handleChange(e)}
                placeholder="Name of Contact Person"
              />
            </td>
          </tr>
          <tr>
            <td>
              <p> Phone</p>
            </td>
            <td>
              <FormInput
                name="contact/phone"
                value={input.contact.phone}
                onChange={(e) => handleChange(e)}
                placeholder="Phone"
              />
            </td>
          </tr>
          <tr>
            <td>
              <p> Email</p>
            </td>
            <td>
              <FormInput
                name="contact/email"
                value={input.contact.email}
                onChange={(e) => handleChange(e)}
                placeholder="Email"
              />
            </td>
          </tr>
        </table>

        <br />
        <br />
      </div>

      <div
        className="no-scroll-bar"
        style={{
          flex: 1,
          display: 'flex',
          position: 'absolute',
          bottom: 0,
          width: '98%',
          backgroundColor: 'white',
          padding: '1.2rem 2rem',
        }}
      >
        <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}>
          <AppButton
            title="CLOSE"
            buttoncolor={'red'}
            onClick={() => setModalOpen(false)}
          />

          <AppButton
            title="SAVE"
            buttoncolor={'green'}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  );
}

export default UpdateRegistration;
