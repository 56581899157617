import React, { useEffect, useState, useRef } from "react";
import ScreenWrapper from "../../ScreenWrapper";

import PlayerTable from "../PlayerTable";
import AddButton from "../../AddButton";
import {
  createPlayersFunction,
  listPlayersFunction,
  deletePlayersFunction,
} from "../../api/Player";

import {
  createTeamFunction,
  deleteTeamFunction,
  updateTeamFunction,
  listTeamsFunction,
  getTeamsFunction,
} from "../../api/Teams";
import { Modal, Button, Row } from "react-bootstrap";
import CreateNotifications from "../../Notifications";

function Player(props) {
  const styles = {
    margin: 40,
    textAlign: "center",
  };
  const [players, setPlayers] = useState([]);
  const [teams, setteams] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedid, setselectedid] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const selectedRow = async (rowData) => {
    setselectedid(rowData.id);
    handleShow();
  };

  const deletePlayer = async () => {
    await deletePlayersFunction(selectedid)
      .then((resp) => {
        if (resp.type === "success") {
          CreateNotifications("success", "Player successfully Deleted");
          // props.history.push("/admin/player");
        } else throw resp;
      })
      .catch((err) => {
        CreateNotifications(err.type, err.message);
      });
    // console.log(resp);
    // CreateNotifications
    handleClose();
    listPlayers();
    // console.log(selectedid);
  };

  const updateplayer = async (rowData) => {
    props.history.push("/admin/updateplayer/" + rowData.id);
  };

  const listPlayers = async () => {
    const resp = await listPlayersFunction();

    setPlayers(resp.data.data.listPlayers.items);
  };

  useEffect(async () => {
    listPlayers();
    // const resp1 = await listTeamsFunction();
    // setteams(resp1.data.data.listTeams.items);
    // console.log(resp1.data.data.listTeams.items);
  }, []);

  return (
    <ScreenWrapper>
      <div style={styles}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1rem",
          }}
        >
          <h3>Players</h3>
          <AddButton
            style={{ width: "250px" }}
            title={"ADD NEW PLAYER"}
            onClick={() => {
              props.history.push("/admin/createplayer");
            }}
          />
        </div>
        <PlayerTable
          players={players}
          teams={teams}
          selectedRow={selectedRow}
          updateplayer={updateplayer}
        />
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Modal title</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            I will not close if you click outside me. Don't even try to press
            escape key.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="danger" onClick={deletePlayer}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </ScreenWrapper>
  );
}

export default Player;
