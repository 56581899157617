import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Modal,
  Button,
} from "react-bootstrap";
import AuthAPI from "../../api/Auth";
import { getUserByEmail } from "../../api/User";
import Loader from "../../Loader";
import classes from "../../../assets/css/Dashboard.module.css";
import PlayerSidebar from "../Players/PlayerSidebar";
import PlayerSidebarWrapper from "./PlayerSidebarWrapper";
import "./styles/Dashboard.css";
import Amplify, { Storage, Analytics } from "aws-amplify";
import ImageCard from "../../Skeletons/ImageCard";
import CreateNotifications from "../../Notifications";
import { getImagesByFaces, getSignedUrls } from "../../api/faceAI";
import { AiOutlineClose } from "react-icons/ai";
import { BrowserView, MobileView } from "react-device-detect";

function PlayerDashboard(props) {
  const [Data, setData] = useState([]);

  const [Confidence, setConfidence] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isModal, setModal] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [imgName, setImgName] = useState("");
  const [c_id, setc_id] = useState("757next");
  const [email, setEmail] = useState("");
  const [profReady, setProfileReady] = useState(false);
  const [loader, setLoader] = useState(true);
  const [profileS3URL, setprofileS3URL] = useState("");
  const [userdata, setuserdata] = useState("");
  const modalHandler = (imageUrl) => {
    setModal(!isModal);
    setModalImage(imageUrl);
  };

  useEffect(async () => {
    try {
      var resp = await AuthAPI.currentAuthenticatedUser();
      setEmail(resp.data.attributes.email);
      var res = await getUserByEmail(resp.data.attributes.email);
      console.log(res);
      var user = res.data.data.SearchByEmail.items[0];
      setuserdata(user);

      if (user.profilePic) {
        const signedURL = await Storage.get(user.profilePic, {
          expires: 60000,
        });
        setprofileS3URL(signedURL);
        setProfileReady(true);
      }
      setLoader(false);
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  }, []);

  const initialLoad = async () => {
    var keys = [];
    var Confidence = [];
    var response = await getImagesByFaces({
      key: userdata.id,
      c_id: c_id,
    });
    console.log(response);
    console.log("Faces Matched");
    if (response["data"].length == 0) {
      CreateNotifications("warning", "No Matching Images Found!");
    } else {
      CreateNotifications(
        "success",
        response["data"].length.toString() + " Matching Images Found!"
      );
    }
    var test = [];
    response["data"] &&
      response["data"].forEach((entry) => {
        keys.push(entry.Face.ExternalImageId);
        Confidence.push(entry.Similarity);
        test.push(entry.Face.FaceId);

        // console.log(entry.Face.ExternalImageId);
      });

    console.log(test);
    setConfidence(Confidence);
    // console.log(keys);
    var signedUrls = await getSignedUrls(keys);
    // console.log(signedUrls["data"]);
    setData(signedUrls["data"]);

    var response = await AuthAPI.currentSession();
    // var resp = await createLogsFunction({
    //   username: response.data.idToken.payload.email,
    //   searchimageName: imgName,
    //   countResults: signedUrls["data"].length,
    // });
    // console.log(resp);

    // console.log("data", Data);

    return signedUrls["data"];
  };

  const searchMatchingFaces = async () => {
    // const blob = imageUrl.current.files[0];
    // CreateNotifications("warning", "Uploading");
    Analytics.record({
      name: "Button",
      // Attribute values must be strings
      attributes: { type: "Search Faces", immediate: true },
    });
    setLoading(true);
    // const result = await Storage.put(filename, imageUrl);
    {
      CreateNotifications("success", "Searching Matching Faces ");
      setImageUrl(null);
      setTimeout(() => {
        setLoading(false);
      }, 4000);
      initialLoad();
      setData([]);
    }
    // console.log(result);
  };

  return (
    <PlayerSidebarWrapper>
      <Loader show={loader} />
      <Modal show={isModal} fullscreen={true} onHide={() => modalHandler}>
        <Modal.Body>
          <Row>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <AiOutlineClose
                color="#424242"
                size={36}
                style={{ position: "absolute", top: "3%", right: "5%" }}
                onClick={modalHandler}
              />
              <img
                src={modalImage}
                style={{
                  height: "50vw",
                  width: "50vw",
                  padding: "2rem",
                }}
                alt="..."
              />
            </div>
          </Row>
          <Row className="justify-content-md-center text-center">
            <a href={modalImage} target="_blank" download>
              {" "}
              <Button size="sm" variant={"primary"}>
                Download Image
              </Button>
            </a>
          </Row>
        </Modal.Body>
      </Modal>

      <Row className="justify-content-md-center">
        <Col md={12}>
          <div style={Style.grid}>
            <div style={Style.imageContainer}>
              <MobileView>
                <img
                  src={profileS3URL == "" ? "/face.jpg" : profileS3URL}
                  alt="scanner"
                  style={{ width: "15vh" }}
                />
              </MobileView>
              <BrowserView>
                <img
                  src={profileS3URL == "" ? "/face.jpg" : profileS3URL}
                  alt="scanner"
                  style={Style.scanner}
                />
              </BrowserView>

              <p>
                {profileS3URL != "" ? (
                  <a href="/player/updateProfile">Update</a>
                ) : (
                  ""
                )}
              </p>
              <h6 style={Style.text}>
                <b>Search players (AI Image Search)</b>
              </h6>
            </div>
            <div style={Style.text_center}>
              Choose Player's Image you want to search
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                margin: "20px",
              }}
            >
              {!profReady && (
                <p style={{ color: "red" }}>
                  Please complete your profile by updating your profilePic!{" "}
                </p>
              )}

              {profileS3URL && (
                <>
                  <div style={{ margin: "10px" }}>
                    <Form.Select
                      value={c_id}
                      onChange={(e) => setc_id(e.target.value)}
                    >
                      <option value="757nextlevel22">
                        757 League - 2022 - Summer
                      </option>
                      <option value="757next">
                        757 League - 2021 - Summer
                      </option>
                      <option value="wrestling">
                        Wrestling - 2021 - Winter
                      </option>
                      <option value="basketball">
                        Basketball - 2021 - Winter
                      </option>
                      <option value="Eagles2022FB">Eagles2022FB</option>
                    </Form.Select>
                  </div>
                  <Button
                    // style={{ paddingTop: "0" }}
                    onClick={() => searchMatchingFaces()}
                    variant={"primary"}
                  >
                    SEARCH
                  </Button>
                </>
              )}
            </div>
          </div>
          {isLoading ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  margin: "5px",
                  padding: "5px",
                  justifyContent: "center",
                }}
              >
                <ImageCard />
                <ImageCard />
                <ImageCard />
                <ImageCard />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  margin: "5px",
                  padding: "5px",
                  justifyContent: "center",
                }}
              >
                <ImageCard />
                <ImageCard />
                <ImageCard />
                <ImageCard />
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                margin: "5px",
                padding: "5px",
                justifyContent: "center",
              }}
            >
              {Data &&
                Data.map(function (imgurl, i) {
                  return (
                    <>
                      {/* <p>{Confidence[i]}</p> */}

                      <div
                        style={{
                          paddingRight: "5px",
                          margin: "5px",
                          maxHeight: "400px",
                          overflow: "hidden",
                          position: "relative",
                          height: "38vh",
                          borderRadius: "8px",
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                          maxWidth: "400px",
                          width: "38vh",
                          backgroundPosition: "center",
                          backgroundrepeat: "no-repeat",
                          backgroundClip: "border-box",
                          backgroundBlendMode: "exclusion",
                          backgroundSize: "cover",
                          backgroundImage: `url(${imgurl})`,
                        }}
                        className={classes.container}
                      >
                        <div
                          className={classes.div}
                          onClick={() => modalHandler(imgurl)}
                        >
                          View Full Size
                        </div>
                        <div style={Style.parent}>
                          {/* <img style={Style.image1} src={Data[0]} /> */}
                          {/* <img style={Style.image2} src="https://via.placeholder.com/50" /> */}
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          )}
        </Col>
      </Row>
    </PlayerSidebarWrapper>
  );
}
const Style = {
  parent: {
    position: "relative",
    top: 0,
    left: 0,
  },
  image1: {
    position: "relative",
    top: 0,
    left: 0,
    border: "1px red solid",
  },
  image2: {
    position: "absolute",
    top: "30px",
    left: "30px",
    border: "1px green solid",
  },
  grid: {
    display: "grid",
    paddingTop: "4rem",
    alignItems: "start",
    // rowGap: "3rem",
    // width: "40vw",
    justifyItems: "center",
    margin: "auto",
    placeItems: "center",
  },
  text: {
    fontSize: "18px",
    textAlign: "center",
    padding: "5px",
  },
  text_center: {
    textAlign: "center",
    paddingTop: "5px",
  },
  scanner: {
    width: "6vw",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
};

export default PlayerDashboard;
