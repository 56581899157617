import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { NavLink } from "react-router-dom";

function NavbarModal({ toClose }) {
  return (
    <div className="navbar-modal">
      <div onClick={toClose} className="close">
        <AiOutlineClose size={28} color="white" />
      </div>
      <NavLink
        onClick={toClose}
        activeStyle={{ color: "#c60c30" }}
        exact
        to="/"
        className="item"
      >
        HOME
      </NavLink>
      <NavLink
        activeStyle={{ color: "#c60c30" }}
        exact
        to="/events"
        className="item"
        onClick={toClose}
      >
        EVENTS
      </NavLink>
      <a href="/player" className="item">
        PLAYER
      </a>
      {/* <a href="/" className="item">
        Video
      </a>
      <a href="/" className="item">
        Photos
      </a>
      <a href="/" className="item">
        Schedule
      </a>
      <a href="/" className="item">
        Team
      </a>
      <a href="/" className="item">
        Training Camp
      </a>
      <a href="/" className="item">
        Community
      </a>
      <a href="/" className="item">
        Tickets
      </a> */}
    </div>
  );
}

export default NavbarModal;
