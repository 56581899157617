import React from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./components/routes/Routes";
import "./assets/css/styles.css";
import Footer from "./components/Footer";
import { CookiesProvider } from "react-cookie";
import NotificationContainer from "react-notifications/lib/NotificationContainer";
import "react-notifications/lib/notifications.css";
import "react-image-crop/dist/ReactCrop.css";
import HeroBanner from "./components/HeroBanner";
import Navbar from "./components/Navbar";
import Amplify, { Analytics } from "aws-amplify";
import awsconfig from "./aws-exports";
Amplify.configure(awsconfig);

function App() {
  return (
    <CookiesProvider>
      <BrowserRouter>
        <HeroBanner />
        <Navbar />
        <Routes />
        <Footer />
        <NotificationContainer />
      </BrowserRouter>
    </CookiesProvider>
  );
}

export default App;
