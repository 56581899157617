import React, { useState, useRef, useEffect } from "react";
import Modal from "react-modal";
import { getTeamsFunction, listTeamsFunction } from "../api/Teams";
import AppButton from "../AppButton";
import CreateNotifications from "../Notifications";

function ViewTeam({ setView, data }) {
  // const [data, setData] = useState([]);

  useEffect(async () => {
    console.log(data);
    // const respo = await getTeamsFunction();
  }, []);
  return (
    <Modal
      isOpen={true}
      style={{
        content: {
          width: "50vw",
          left: "50%",
          right: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        },
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3>Team</h3>
        <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
          <AppButton
            title="X"
            buttoncolor={"red"}
            onClick={() => setView(false)}
          />
        </div>
      </div>
      <div>
        <h4>Team Name : {data.Name} </h4>
        <h4>Location : {data.Location}</h4>
        <h4>Coach Name: {data.CoachName}</h4>
        <h5>No of Players: {data.Players.items.length}</h5>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: "2rem",
        }}
      >
        <div>
          <AppButton
            title="Close"
            buttoncolor="red"
            onClick={() => setView(false)}
          />
        </div>
        <div style={{ marginLeft: "1rem" }}>
          {" "}
          <AppButton title="Update" />
        </div>
      </div>
    </Modal>
  );
}

export default ViewTeam;
