import Auth from "@aws-amplify/auth";
import { input } from "@aws-amplify/ui";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useHistory } from "react-router";
import AuthAPI from "../../api/Auth";
import storage from "../../api/storage";
import { createUserFunction } from "../../api/User";
import CreateNotifications from "../../Notifications";

function PlayerForgetPasswordSubmit(props) {
  useEffect(() => {
    // console.log(props);
    setUsername(props.location.state.username);
  });

  const confirmSign = async (e) => {
    e.preventDefault();
    console.log(username);
  };

  const forgettPasswordSubmit = async (e) => {
    e.preventDefault();
    var res = await AuthAPI.resetPasswordSubmit(username, code, password);

    if (res.type == "error") {
      CreateNotifications("error", res.message);
    } else {
      CreateNotifications("success", res.message);
      history.push("/player");
    }
    console.log(res);
  };

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");

  const [loader, setLoader] = useState(false);
  const history = useHistory();
  return (
    <div>
      {" "}
      <div>
        <Container>
          <Row className="justify-content-md-center" style={{ margin: "10px" }}>
            <Col xs lg="6">
              <h3 style={{ marginBottom: "30px" }}>
                {" "}
                Player's Forget Password
              </h3>
              <Form onSubmit={forgettPasswordSubmit}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Your Registered Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="username"
                    value={username}
                    disabled
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Verification Code</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Verification Code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Your Registered Email</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="New Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <p>
                  Already have a account ?{" "}
                  <span style={{ color: "blue" }}>
                    {" "}
                    <a href="/player">Sign In</a>{" "}
                  </span>{" "}
                </p>
                <Button disabled={loader} variant="primary" type="submit">
                  Confirm
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default PlayerForgetPasswordSubmit;
