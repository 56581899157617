import React from "react";
import { colors } from "../config/color";
// import { AiOutlineFileImage } from "react-icons/ai";

function AddButton({ title, onClick, buttoncolor = "blue" }) {
  return (
    <>
      <button
        onClick={onClick}
        style={{
          ...Styles.button,
          backgroundColor: colors[buttoncolor],
        }}
      >
        {title}
      </button>
    </>
  );
}

const Styles = {
  button: {
    border: "none",
    color: "white",
    padding: "5px",
    textAlign: "center",
    textDecoration: "none",
    display: "inlineBlock",
    fontSize: "16px",
    // fontWeight: "bold",
    borderRadius: "4px",
    width: "15%",
  },
};
export default AddButton;
