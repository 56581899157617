import React from "react";
import ScreenWrapper from "./ScreenWrapper";
// import VideoPlayer from "react-video-js-player";
import VideoPlayer from "./Hooks/VideoPlayer";
import "videojs-contrib-quality-levels";

function VideoJS(props) {
  const videoJsOptions = {
    autoplay: false,
    playbackRates: [0.5, 1, 1.25, 1.5, 2],
    width: 720,
    height: 300,
    controls: true,
    sources: [
      {
        src: "https://d1a2nakvpdho11.cloudfront.net/02175ca2-952a-471a-8b7c-2158cdab3f28/AppleHLS1/10000000_337371274518413_7072524752984607757_n.m3u8",
      },
    ],
  };
  const videoSrc =
    "https://d1a2nakvpdho11.cloudfront.net/02175ca2-952a-471a-8b7c-2158cdab3f28/AppleHLS1/10000000_337371274518413_7072524752984607757_n.m3u8";

  const onPlayerReady = (player) => {
    // console.log("Player is ready: ", player);
    let qualityLevels = player.qualityLevels();
    console.log(qualityLevels);
  };
  return (
    <ScreenWrapper>
      <div>
        <p>Own Video on Demand service</p>
        <VideoPlayer videoSrc={videoSrc} />
        {/* <VideoPlayer
          src={videoSrc}
          height="420"
          width="720"
          onReady={(obj) => onPlayerReady(obj)}
          volume={true}
        /> */}
        {/* <Videojs {...videoJsOptions} /> */}
      </div>
    </ScreenWrapper>
  );
}

export default VideoJS;
