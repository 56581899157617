import React, { useState } from "react";
import { AiOutlineDown, AiOutlineClose } from "react-icons/ai";
import Carousal from "./Carousal";
import HeadStack from "./HeadStack";

function Home() {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <main className="home-page">
      <section className="container_grids">
        <section className="notfication-bar">
          <div className="inner_grid">
            <div className="content">
              <span>NEW</span>
              <div style={{ paddingTop: "5px" }}>
                <a href="https://forms.gle/gMjowQuMxUba7VAs6" target="_blank">
                  <h3> 757 Spring & Summer Sign up 2022 </h3>
                </a>
              </div>

              {/* <h4>{new Date().toDateString()}</h4>
              <h4>|</h4>
              <h4>12:30 AM - 11:55 PM</h4> */}

              {showDetails ? (
                <div
                  className="show_details"
                  onClick={() => setShowDetails(!showDetails)}
                >
                  <h4>Close</h4>
                  <AiOutlineClose />{" "}
                </div>
              ) : (
                <div
                  className="show_details"
                  onClick={() => setShowDetails(!showDetails)}
                >
                  <h4>Next</h4>
                  <AiOutlineDown />{" "}
                </div>
              )}
            </div>
          </div>
        </section>
        <section
          style={showDetails ? {} : { display: "none" }}
          className="hidden-details"
        >
          <div className="header">
            <h3>Title</h3>
          </div>
          <div className="items">
            <div className="date-time">
              <h5>{new Date().toDateString()}</h5>
              <h5>12:30 AM - 11:55 PM</h5>
            </div>
            <div className="title">
              <span>Replay</span>
              <h3> waiitt sports Replay - </h3>
            </div>
            <p className="body">
              Listen to replays of recent editions of 757 next level sports
              Unfiltered.
            </p>
          </div>
          <div className="items">
            <div className="date-time">
              <h5>{new Date().toDateString()}</h5>
              <h5>12:30 AM - 11:55 PM</h5>
            </div>
            <div className="title">
              <span>Replay</span>
              <h3> waiitt sports Replay - </h3>
            </div>
            <p className="body">
              Listen to replays of recent editions of waiitt sports Unfiltered.
            </p>
          </div>
          <div className="items">
            <div className="date-time">
              <h5>{new Date().toDateString()}</h5>
              <h5>12:30 AM - 11:55 PM</h5>
            </div>
            <div className="title">
              <span>Replay</span>
              <h3> waiitt sports Replay - </h3>
            </div>
            <p className="body">
              Listen to replays of recent editions of waiitt sports Unfiltered.
            </p>
          </div>
        </section>
      </section>

      <section className="home-header">
        <div className="inner_grid">
          <div className="carousal-display">
            <Carousal />
          </div>
          <div className="headline_logo_container">
            <div className="headline-stack">
              <HeadStack />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Home;
