import React from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";

import PlayerSidebar from "../Players/PlayerSidebar";
import "./styles/Dashboard.css";

function PlayerSidebarWrapper(props) {
  return (
    <>
      <Container fluid>
        <Row>
          <Col md={2}>
            <PlayerSidebar />
          </Col>
          <Col md={10}>{props.children}</Col>
        </Row>
      </Container>
    </>
  );
}

export default PlayerSidebarWrapper;
