import React, { useEffect, useRef, useState } from 'react';
import { ListRegistration } from '../api/registrations';
import Card from '../Card';
import ViewRegistration from '../modals/ViewRegistration';
import CreateNotifications from '../Notifications';

function Events() {
  const [view, setView] = useState(false);
  const [data, setData] = useState([]);
  const [showData, setShowData] = useState({});
  const isSubscribed = useRef(true);

  useEffect(() => {
    const initialLoad = async () => {
      await ListRegistration()
        .then((res) => {
          if (res.type === 'success') {
            if (!isSubscribed.current) return;
            setData(res.data.data.listRegistrations.items);
          } else throw res;
        })
        .catch((err) => {
          CreateNotifications(err.type, err.message);
        });
    };
    initialLoad();
  }, []);
  return (
    <main>
      {view && <ViewRegistration setModalOpen={setView} data={showData} />}

      <section style={Styles.container}>
        <div style={Styles.parentCardContainer}>
          {data.length
            ? data.map((item) => {
                return (
                  <Card
                    key={item.id}
                    data={item}
                    setView={setView}
                    onClick={setShowData}
                  />
                );
              })
            : null}
        </div>
      </section>
    </main>
  );
}

const Styles = {
  head: { display: 'flex', flex: '1' },
  container: {
    width: '100%',
    minHeight: '70vh',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '3rem 0',
    backgroundColor: 'white',
  },
  parentCardContainer: {
    display: 'flex',
    flex: '3',
    padding: '10px',
    flexWrap: 'wrap',
  },
};

export default Events;
