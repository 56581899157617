import React, { useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import NavbarModal from "./NavbarModal";

function Navbar() {
  const [modal, setModal] = useState(false);
  const history = useHistory();

  return !(
    history.location.pathname.includes("/player") ||
    history.location.pathname.includes("/admin")
  ) ? (
    <>
      {modal && <NavbarModal toClose={() => setModal(false)} />}
      <section className="navbar">
        <div className="left">
          <NavLink
            exact
            activeStyle={{ color: "#c60c30" }}
            to="/"
            className="item"
          >
            HOME
          </NavLink>
          <NavLink
            activeStyle={{ color: "#c60c30" }}
            to="/events"
            className="item"
            exact
          >
            EVENT
          </NavLink>
          {/* <NavLink
            activeStyle={{ color: "#c60c30" }}
            to="/player"
            className="item"
            exact
          >
            Player
          </NavLink> */}
          <a href="/player" className="item">
            PLAYER
          </a>
          {/* <a href="/" className="item">
            Video
          </a>
          <a href="/" className="item">
            Photos
          </a>
          <a href="/" className="item">
            Schedule
          </a>
          <a href="/" className="item">
            Team
          </a>
          <a href="/" className="item">
            Training Camp
          </a>
          <a href="/" className="item">
            Community
          </a>
          <a href="/" className="item">
            ...
          </a> */}
        </div>
        {/* <div className="right">
          <AiOutlineSearch color="white" />
          <a href="/" className="item">
            Tickets
          </a>
          <a href="/" className="item">
            Proshops
          </a>
          <a href="/" className="item">
            Watch
          </a>
          <a href="/" className="item">
            Radio
          </a>
        </div> */}
        <div onClick={() => setModal(true)} className="humburger">
          <AiOutlineMenu color={"white"} size={24} />
        </div>
      </section>
    </>
  ) : null;
}

export default Navbar;
