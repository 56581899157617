import Auth from "@aws-amplify/auth";
import { input } from "@aws-amplify/ui";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useHistory } from "react-router";
import AuthAPI from "../../api/Auth";
import storage from "../../api/storage";
import { createUserFunction } from "../../api/User";
import CreateNotifications from "../../Notifications";

function PlayerVerificationCode(props) {
  useEffect(() => {
    // console.log(props);
    setUsername(props.location.state.username);
  });

  const create_User = async (input) => {
    var res = await createUserFunction({
      email: username,
      name: props.location.state.name,
    });
  };
  const confirmSign = async (e) => {
    e.preventDefault();
    var res = await AuthAPI.confirmSignUp(username, password);
    if (res.type == "success") {
      CreateNotifications("success", "User SignUp Successful");
      await create_User();
      history.push("/player");
    } else {
      CreateNotifications("error", res.message);
    }
  };
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [loader, setLoader] = useState(false);
  const history = useHistory();
  return (
    <div>
      <Container>
        <Row className="justify-content-md-center" style={{ margin: "10px" }}>
          <Col xs lg="6">
            <h3 style={{ marginBottom: "30px" }}>
              {" "}
              Player's Verification Code
            </h3>
            <Form onSubmit={confirmSign}>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Your Verification Code</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Code"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <p>
                Already have a account ?{" "}
                <span style={{ color: "blue" }}>
                  {" "}
                  <a href="/player">Sign In</a>{" "}
                </span>{" "}
              </p>
              <Button disabled={loader} variant="primary" type="submit">
                Confirm
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PlayerVerificationCode;
