import Amplify, { Storage } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";
import awsmobile from "../../aws-exports";
import { listLandingPageContent } from "../api/LandingPageApi";
import CreateNotifications from "../Notifications";
// import { useCookies } from 'react-cookie';

Amplify.configure(awsmobile);
function Carousal() {
  const [index, setIndex] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    const initialLoad = async () => {
      await listLandingPageContent()
        .then((res) => {
          if (res.type === "success") {
            setData(Object.entries(JSON.parse(res.data.carousel)));
          } else throw res;
        })
        .catch((err) => {
          CreateNotifications("error", err.message);
        });
    };

    initialLoad();
  }, []);

  const handleIndexChange = (value) => {
    if (index + value > data.length - 1) setIndex(0);
    else if (index + value < 0) setIndex(data.length - 1);
    else setIndex(index + value);
  };

  return (
    <div className="carousel-container">
      {data.length ? <CarousalItem data={data[index]} /> : null}
      <div className="carousel-action-indicators">
        <div
          onClick={() => handleIndexChange(-1)}
          className="left-arrow-action"
        >
          <AiOutlineLeft />
        </div>
        <div
          onClick={() => handleIndexChange(1)}
          className="right-arrow-indicators"
        >
          <AiOutlineRight />
        </div>
      </div>

      <div className="carousel-dots-container">
        {data.map((item, i) => {
          return (
            <div
              onClick={() => setIndex(i)}
              key={i}
              className={`carousel-dots ${
                i === index && "carousel-dots-active"
              }`}
            ></div>
          );
        })}
      </div>
    </div>
  );
}

export default Carousal;

const CarousalItem = ({ data }) => {
  // const [cookie, setCookie] = useCookies(['images']);
  const [image, setImage] = useState("");

  useEffect(() => {
    let isSubscribed = true;
    const initialLoad = async () => {
      // if (cookie[data[0]]) {
      //   setImage(cookie[data[0]]);
      //   return;
      // }
      if (data[1].img_url.length) {
        const url = await Storage.get(data[1].img_url, { expires: 60000 });
        if (!isSubscribed) return;
        setImage(url);
        // setCookie(data[0], url, { path: '/' });
      }
    };
    initialLoad();

    return () => (isSubscribed = false);
  }, [data]);
  return (
    data[1] && (
      <div
        className="carousel-item"
        style={{ display: "block", float: "none" }}
      >
        <div
          style={{ backgroundImage: `url(${image})` }}
          className={`carousel-image-container`}
        ></div>
        <div className="carousel-details">
          <h1>{data[1].title}</h1>
          <p>{data[1].description}</p>
        </div>
      </div>
    )
  );
};
