import React, { useEffect } from 'react';
import { Redirect } from 'react-router';
import AuthAPI from '../../api/Auth';
import storage from '../../api/storage';

function Logout() {
  useEffect(() => {
    const logut = async () => {
      await storage.removeToken();
      await localStorage.clear();
      await AuthAPI.signOut();
    };
    logut();
  });
  return <Redirect to="/admin/login" />;
}

export default Logout;
