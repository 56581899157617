import React from 'react';
import { IoIosEye, IoIosEyeOff } from 'react-icons/io';

export function Input({ ...otherProps }) {
  return (
    <div className="input">
      <input {...otherProps} />
    </div>
  );
}

export function Password({ show, setShow, ...otherProps }) {
  return (
    <div className="input">
      <div className="p_input">
        <input {...otherProps} />
        {show ? (
          <IoIosEye onClick={setShow} color={'#626262'} size={24} />
        ) : (
          <IoIosEyeOff onClick={setShow} color={'#626262'} size={24} />
        )}
      </div>
    </div>
  );
}
