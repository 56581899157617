import Amplify, { Storage } from "aws-amplify";
import React, { useEffect, useRef, useState } from "react";
import ReactModal from "react-modal";
import awsmobile from "../../../aws-exports";
import {
  createLandingPageContent,
  listLandingPageContent,
  updateLandingPageContent,
} from "../../api/LandingPageApi";
import ImageCropper from "../../ImageCropper";
import Loader from "../../Loader";
import CreateNotifications from "../../Notifications";
import ScreenWrapper from "../../ScreenWrapper";

Amplify.configure(awsmobile);

function AdminHome() {
  const bannerInput = useRef(null);
  const [email, setEmail] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [youtube, setYoutube] = useState("");
  const [snapchat, setSnapchat] = useState("");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [carousels, setCarousels] = useState(null);
  const [bannerImage, setBannerImage] = useState("");

  useEffect(() => {
    initialLoad();
  }, []);
  const initialLoad = async () => {
    setLoading(true);
    await listLandingPageContent()
      .then(async (res) => {
        if (res.type === "success") {
          setData(res.data);
          if (res.data) {
            const sLinks = JSON.parse(res.data.socialMedia);
            const cImage = JSON.parse(res.data.carousel);
            setCarousels(cImage);
            setEmail(sLinks.email);
            setFacebook(sLinks.facebook);
            setInstagram(sLinks.instagram);
            setSnapchat(sLinks.snapchat);
            setTwitter(sLinks.twitter);
            setYoutube(sLinks.youtube);
            const imageUrl = res.data.banner_img.length
              ? await Storage.get(res.data.banner_img, { expires: 60000 })
              : "";
            setBannerImage(imageUrl);
          }
        } else throw res;
      })
      .catch((err) => {
        CreateNotifications("error", err.message);
      });

    setLoading(false);
  };

  const addBannerImage = async () => {
    setLoading(true);

    const filename = "banner-image.png";
    const blob = bannerInput.current.files[0];

    await Storage.put(filename, blob);

    if (data && data.id) {
      const input = {
        id: data.id,
        banner_img: filename,
        socialMedia: data.socialMedia,
        carousel: data.carousel,
      };

      await updateLandingPageContent(input)
        .then(async (res) => {
          if (res.type === "success") {
            const url = await Storage.get(res.data.banner_img, {
              expires: 60000,
            });
            CreateNotifications("success", res.message);
            setBannerImage(url);
          } else throw res;
        })
        .catch((err) => {
          CreateNotifications("error", err.message);
        });
    } else {
      const input = {
        carousel: null,
        socialMedia: null,
        banner_img: filename,
      };
      await createLandingPageContent(input)
        .then((res) => {
          if (res.type === "success") {
            const url = Storage.get(res.data.banner_img, { expires: 60000 });
            CreateNotifications("success", res.message);
            setBannerImage(url);
          } else throw res;
        })
        .catch((err) => {
          CreateNotifications("error", err.message);
        });
    }

    setLoading(false);
  };

  const addCarouselImage = async (
    target,
    file,
    title,
    description,
    prev_imgUrl
  ) => {
    setLoading(true);

    console.log("calling");

    const filename = `${target}-image.png`;
    if (file) {
      await Storage.put(filename, file);
    }

    let temp = JSON.parse(data.carousel);

    if (temp && temp[target] !== undefined) {
      temp[target]["img_url"] = file ? filename : prev_imgUrl;
      temp[target]["title"] = title;
      temp[target]["description"] = description;
    } else {
      temp = {
        ...temp,
        [target]: {
          img_url: file ? filename : prev_imgUrl,
          title: title,
          description: description,
        },
      };
    }

    if (data && data.id) {
      const input = {
        id: data.id,
        socialMedia: data.socialMedia || null,
        banner_img: data.banner_img || null,
        carousel: JSON.stringify(temp),
      };
      await updateLandingPageContent(input)
        .then((res) => {
          if (res.type === "success") {
            setData(res.data.data.updateLandingPage);
            setCarousels(res.data.data.updateLandingPage.carousel);
            CreateNotifications("success", res.message);
          } else throw res;
        })
        .catch((err) => {
          CreateNotifications("error", err.message);
        });
    }

    initialLoad();
    setLoading(false);
  };

  const addSocialMediaLinks = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (data && data.id) {
      const input = {
        id: data.id,
        banner_img: data.banner_img || null,
        carousel: data.carousel || null,
        socialMedia: JSON.stringify({
          facebook: facebook,
          instagram: instagram,
          snapchat: snapchat,
          youtube: youtube,
          twitter: twitter,
          email: email,
        }),
      };
      await updateLandingPageContent(input)
        .then((res) => {
          if (res.type === "success") {
            setData(res.data.data.updateLandingPage);
            CreateNotifications("success", res.message);
          } else throw res;
        })
        .catch((err) => {
          CreateNotifications("error", err.message);
        });
    } else {
      const input = {
        banner_img: data ? data.banner_img : null,
        carousel: data ? data.carousel : null,
        socialMedia: JSON.stringify({
          facebook: facebook,
          instagram: instagram,
          snapchat: snapchat,
          youtube: youtube,
          twitter: twitter,
          email: email,
        }),
      };

      await createLandingPageContent(input)
        .then((res) => {
          if (res.type === "success") {
            setData(res.data.data.createLandingPage);
            CreateNotifications("success", res.message);
          } else throw res;
        })
        .catch((err) => {
          CreateNotifications("error", err.message);
        });
    }

    setEmail("");
    setFacebook("");
    setInstagram("");
    setSnapchat("");
    setTwitter("");
    setYoutube("");
    setLoading(false);

    initialLoad();
  };

  const handleCancel = (e) => {
    e.preventDefault();
    const sLinks = JSON.parse(data.socialMedia);
    setEmail(sLinks.email);
    setFacebook(sLinks.facebook);
    setInstagram(sLinks.instagram);
    setSnapchat(sLinks.snapchat);
    setTwitter(sLinks.twitter);
    setYoutube(sLinks.youtube);
  };

  return (
    <ScreenWrapper>
      <Loader show={loading} />
      <section className="headstack_details">
        <section className="admin__home cimages">
          <div className="header">
            <h1>Carousel Images</h1>
            <h3>Add carousel images</h3>
          </div>
          {carousels && (
            <div className="carousel_images">
              <div className="col cimage__col">
                {Object.entries(carousels)
                  .slice(0, 3)
                  .map((item, index) => {
                    return (
                      <CarouselImage
                        key={index}
                        data={item}
                        action={addCarouselImage}
                      />
                    );
                  })}
              </div>
              <div className="col cimage__col">
                {Object.entries(carousels)
                  .slice(3, 6)
                  .map((item, index) => {
                    return (
                      <CarouselImage
                        key={index}
                        data={item}
                        action={addCarouselImage}
                      />
                    );
                  })}
              </div>
            </div>
          )}
        </section>

        <section className="admin__home slink">
          <div className="header">
            <h1>Social Media Links</h1>
            <h3>Add social links</h3>
          </div>

          <form onSubmit={(e) => addSocialMediaLinks(e)}>
            <div className="col">
              <div className="links row-4">
                <label>Email</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter email"
                />
              </div>
              <div className="links row-4">
                <label>Facebook</label>
                <input
                  type="url"
                  value={facebook}
                  onChange={(e) => setFacebook(e.target.value)}
                  placeholder="Enter facebook link"
                />
              </div>
              <div className="links row-4">
                <label>Instagram</label>
                <input
                  type="url"
                  value={instagram}
                  onChange={(e) => setInstagram(e.target.value)}
                  placeholder="Enter instagram link"
                />
              </div>
            </div>

            <div className="col">
              <div className="links row-4">
                <label>Snapchat</label>
                <input
                  type="url"
                  value={snapchat}
                  onChange={(e) => setSnapchat(e.target.value)}
                  placeholder="Enter snapchat link"
                />
              </div>
              <div className="links row-4">
                <label>Twitter</label>
                <input
                  type="url"
                  value={twitter}
                  onChange={(e) => setTwitter(e.target.value)}
                  placeholder="Enter twitter link"
                />
              </div>
              <div className="links row-4">
                <label>Youtube</label>
                <input
                  type="url"
                  value={youtube}
                  onChange={(e) => setYoutube(e.target.value)}
                  placeholder="Enter youtube link"
                />
              </div>
            </div>
            <div className="form__btn_container">
              <button className="form_submit_button" type="submit">
                Update
              </button>
              <button
                onClick={handleCancel}
                className="form_submit_button cancel"
                type="reset"
              >
                Cancel
              </button>
            </div>
          </form>
        </section>

        <section className="admin__home banner">
          <div className="header">
            <h1>Banner Image</h1>
            <h3>Add banner image</h3>
          </div>
          <div className="banner__image-container">
            <img src={bannerImage} alt="banner" />
          </div>

          <div className="banner_container">
            <input
              ref={bannerInput}
              type="file"
              accept=".jpg,.png,.jpeg,.svg,images/*"
            />
            <button
              onClick={(e) => {
                e.preventDefault();
                addBannerImage();
              }}
              type="submit"
              className="btn__submit-btn "
            >
              Upload
            </button>
          </div>
        </section>
      </section>
    </ScreenWrapper>
  );
}

export default AdminHome;

const CarouselImage = ({ data, action }) => {
  const [image, setImage] = useState("");
  const [title, setTitle] = useState(data[1] ? data[1].title : "");
  const [imageUrl, setImageUrl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [description, setDescription] = useState(
    data[1] ? data[1].description : ""
  );

  useEffect(() => {
    const initialLoad = async () => {
      if (data[1].img_url && data[1].img_url.length) {
        const imageUrl = await Storage.get(data[1].img_url, { expires: 60000 });
        setImage(imageUrl);
      } else return;
    };

    initialLoad();
  }, [data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    action(data[0], imageUrl, title, description, data[1].img_url);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setTitle(data[1].title);
    setImageUrl(null);
    setDescription(data[1].description);
  };

  return (
    <>
      <ReactModal isOpen={isOpen}>
        <ImageCropper setImage={setImageUrl} close={() => setIsOpen(false)} />
      </ReactModal>
      <div className="row-4 cimage">
        <div className="img__container">
          <img src={image} alt={data[1] ? data[1].title : "carousel"} />
        </div>
        <div className="form__carousel-images">
          <form onSubmit={handleSubmit}>
            <div className="input">
              <label>Title</label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
                placeholder="Enter title"
                autoCapitalize="true"
              />
            </div>
            <div className="input">
              <label>Description</label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                placeholder="Enter description"
                autoCorrect="true"
              />
            </div>
            <div className="input">
              <button
                className="file_input_button"
                type="button"
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                Select Image
              </button>
              {imageUrl && <p className="success_line">Image selected</p>}
            </div>
            <div className="form__btn_container">
              <button className="form_submit_button" type="submit">
                Update
              </button>
              <button
                onClick={handleCancel}
                className="form_submit_button cancel"
                type="reset"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
