import Auth from "@aws-amplify/auth";
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form, Image } from "react-bootstrap";
import { useHistory } from "react-router";
import AuthAPI from "../../api/Auth";
import storage from "../../api/storage";
import CreateNotifications from "../../Notifications";
import Logo from "../../../assets/images/logo.png";
import Amplify, { Analytics } from "aws-amplify";

function PlayerLogin(props) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [loader, setLoader] = useState(false);
  const history = useHistory();

  useEffect(() => {
    // console.log(props);
    test();
  }, []);

  const test = async () => {
    var res = await AuthAPI.currentAuthenticatedUser();
    console.log(res);
  };

  const resendVerification = async (e) => {
    var res = await AuthAPI.resendConfirmationCode(username);
    console.log(res);
    if (res.type == "success") {
      CreateNotifications("success", "Verification Code Resent to the user");
      history.push({
        pathname: "/player/verification",
        state: { username: username },
      });
    } else {
      history.push({
        pathname: "/player/verification",
        state: { username: username },
      });
      CreateNotifications("error", res.message);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    Analytics.record({
      name: "Button",
      // Attribute values must be strings
      attributes: { type: "Login", immediate: true },
    });
    setLoader(true);

    await Auth.signIn(username, password)
      .then(async (user) => {
        console.log(user);
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          history.push("/player");
        } else {
          const cred = await AuthAPI.currentCredentials();
          if (cred.type === "success") {
            await storage.storeToken(cred.data);
            history.push("/player/dashboard");
          } else throw cred;
        }
      })
      .catch((err) => {
        if (err.code == "UserNotConfirmedException") {
          resendVerification();
        } else {
          CreateNotifications("error", err.message);
        }
        console.log(err);
        // alert(err.message);
      });

    // setPassword("");
    // setUsername("");
    setLoader(false);
  };
  return (
    <div>
      <Container>
        <Row className="justify-content-md-center" style={{ margin: "10px" }}>
          <Col xs lg="6">
            <Image
              src={Logo}
              thumbnail
              style={{
                marginBottom: "20px",
                alignItems: "center",
                backgroundColor: "darkgrey",
                height: "100px",
              }}
            />
            <h3 style={{ marginBottom: "30px" }}>Player's Login</h3>
            <Form onSubmit={handleLogin}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <p>
                <span style={{ color: "blue" }}>
                  {" "}
                  <a href="/player/forgetpassword">Forget Password?</a>{" "}
                </span>{" "}
              </p>
              <p>
                Don't have a account ?{" "}
                <span style={{ color: "blue" }}>
                  {" "}
                  <a href="/player/signup">SignUp</a>{" "}
                </span>{" "}
              </p>
              <Button disabled={loader} variant="primary" type="submit">
                Login
              </Button>
            </Form>
            <br></br>
            <a href="https://waiitt.com/products-%26-services/ols/products/waittsports-support-your-team-get-your-snaps">
              Support your team with a donation
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PlayerLogin;
