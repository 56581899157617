/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPlayers = /* GraphQL */ `
  query GetPlayers($id: ID!) {
    getPlayers(id: $id) {
      id
      Name
      Gender
      Age
      TeamName
      teamID
      ProfilePicture
      createdAt
      updatedAt
    }
  }
`;
export const listPlayers = /* GraphQL */ `
  query ListPlayers(
    $filter: ModelPlayersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlayers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Name
        Gender
        Age
        TeamName
        teamID
        ProfilePicture
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      Name
      Location
      CoachName
      createdAt
      updatedAt
      Players {
        items {
          id
          Name
          Gender
          Age
          TeamName
          teamID
          ProfilePicture
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Name
        Location
        CoachName
        createdAt
        updatedAt
        Players {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getRegistrations = /* GraphQL */ `
  query GetRegistrations($id: ID!) {
    getRegistrations(id: $id) {
      id
      title
      description
      attachments
      contact
      startdate
      enddate
      eventBanner
      createdAt
      updatedAt
    }
  }
`;
export const listRegistrations = /* GraphQL */ `
  query ListRegistrations(
    $filter: ModelRegistrationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRegistrations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        attachments
        contact
        startdate
        enddate
        eventBanner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLandingPage = /* GraphQL */ `
  query GetLandingPage($id: ID!) {
    getLandingPage(id: $id) {
      id
      banner_img
      carousel
      socialMedia
      createdAt
      updatedAt
    }
  }
`;
export const listLandingPages = /* GraphQL */ `
  query ListLandingPages(
    $filter: ModelLandingPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLandingPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        banner_img
        carousel
        socialMedia
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNews = /* GraphQL */ `
  query GetNews($id: ID!) {
    getNews(id: $id) {
      id
      type
      title
      description
      url
      createdAt
      updatedAt
    }
  }
`;
export const listNews = /* GraphQL */ `
  query ListNews(
    $filter: ModelNewsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        title
        description
        url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLogs = /* GraphQL */ `
  query GetLogs($id: ID!) {
    getLogs(id: $id) {
      id
      username
      searchimageName
      countResults
      createdAt
      updatedAt
    }
  }
`;
export const listLogs = /* GraphQL */ `
  query ListLogs(
    $filter: ModelLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        searchimageName
        countResults
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      email
      profilePic
      gender
      role
      shirtSize
      schoolSystem
      DashboardPictureCollection
      FutureSeasonsToPlay
      CoachTeamPreference
      schoolGrade
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        profilePic
        gender
        role
        shirtSize
        schoolSystem
        DashboardPictureCollection
        FutureSeasonsToPlay
        CoachTeamPreference
        schoolGrade
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchByEmail = /* GraphQL */ `
  query SearchByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SearchByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        profilePic
        gender
        role
        shirtSize
        schoolSystem
        DashboardPictureCollection
        FutureSeasonsToPlay
        CoachTeamPreference
        schoolGrade
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
