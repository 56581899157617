import { API, graphqlOperation } from "aws-amplify";
import {
  createLandingPage,
  deleteLandingPage,
  updateLandingPage,
} from "../../graphql/mutations";
import { getLandingPage, listLandingPages } from "../../graphql/queries";

let responseData = {
  data: null,
  message: "Api not called",
  type: "error",
};

export const createLandingPageContent = async (input) => {
  await API.graphql(graphqlOperation(createLandingPage, { input: input }))
    .then((res) => {
      responseData.data = res;
      responseData.message = "Successfully added the content of landing page";
      responseData.type = "success";
    })
    .catch((err) => {
      responseData.data = null;
      responseData.message = "Something went wrong while adding the content";
      responseData.type = "error";
    });

  return responseData;
};

export const updateLandingPageContent = async (input) => {
  await API.graphql(graphqlOperation(updateLandingPage, { input: input }))
    .then((res) => {
      responseData.data = res;
      responseData.message = "Successfully updated the content of landing page";
      responseData.type = "success";
    })
    .catch((err) => {
      responseData.data = null;
      responseData.message = "Something went wrong while updating the content";
      responseData.type = "error";
    });

  return responseData;
};

export const deleteLandingPageContent = async (id) => {
  await API.graphql(graphqlOperation(deleteLandingPage, { input: { id: id } }))
    .then((res) => {
      responseData.data = res;
      responseData.message = "Successfully deleted the content of landing page";
      responseData.type = "success";
    })
    .catch((err) => {
      responseData.data = null;
      responseData.message = "Something went wrong while deleting the content";
      responseData.type = "error";
    });

  return responseData;
};

export const listLandingPageContent = async () => {
  await API.graphql(graphqlOperation(listLandingPages))
    .then((res) => {
      responseData.data = res.data.listLandingPages.items[0];
      responseData.message =
        "Successfully fetched the contents of landing page";
      responseData.type = "success";
    })
    .catch((err) => {
      console.log(err);
      responseData.data = null;
      responseData.message = "Something went wrong while fetching the content";
      responseData.type = "error";
    });

  return responseData;
};

export const getLandingPageContent = async (id) => {
  await API.graphql(graphqlOperation(getLandingPage, { id: id }))
    .then((res) => {
      responseData.data = res;
      responseData.message = "Successfully fetched the content of landing page";
      responseData.type = "success";
    })
    .catch((err) => {
      responseData.data = null;
      responseData.message = "Something went wrong while fetching the content";
      responseData.type = "error";
    });

  return responseData;
};
