import { API, graphqlOperation } from "aws-amplify";
import {
  createPlayers,
  updatePlayers,
  deletePlayers,
} from "../../graphql/mutations";
import { getPlayers, listPlayers } from "../../graphql/queries";

let responseData = {
  data: null,
  message: "Api not called",
  type: "error",
};

export const createPlayersFunction = async (input) => {
  await API.graphql(graphqlOperation(createPlayers, { input: input }))
    .then((res) => {
      responseData.data = res;
      responseData.message = "Successfully added the Players";
      responseData.type = "success";
    })
    .catch((err) => {
      console.log(err);
      responseData.data = null;
      responseData.message = "Something went wrong while adding the Players";
      responseData.type = "error";
    });

  return responseData;
};

export const updatePlayersFunction = async (input) => {
  await API.graphql(graphqlOperation(updatePlayers, { input: input }))
    .then((res) => {
      responseData.data = res;
      responseData.message = "Successfully updated the Players";
      responseData.type = "success";
    })
    .catch((err) => {
      responseData.data = null;
      responseData.message = "Something went wrong while updating the Players";
      responseData.type = "error";
    });

  return responseData;
};

export const deletePlayersFunction = async (id) => {
  await API.graphql(graphqlOperation(deletePlayers, { input: { id: id } }))
    .then((res) => {
      responseData.data = res;
      responseData.message = "Successfully deleted the Players";
      responseData.type = "success";
    })
    .catch((err) => {
      responseData.data = null;
      responseData.message = "Something went wrong while deleting the Players";
      responseData.type = "error";
    });

  return responseData;
};

export const listPlayersFunction = async () => {
  await API.graphql(graphqlOperation(listPlayers))
    .then((res) => {
      responseData.data = res;
      responseData.message = "Successfully fetched the latest Players";
      responseData.type = "success";
    })
    .catch((err) => {
      responseData.data = null;
      responseData.message = "Something went wrong while fetching the Players";
      responseData.type = "error";
    });

  return responseData;
};

export const getPlayersFunction = async (id) => {
  await API.graphql(graphqlOperation(getPlayers, { id: id }))
    .then((res) => {
      responseData.data = res;
      responseData.message = "Successfully fetched the Playerss";
      responseData.type = "success";
    })
    .catch((err) => {
      responseData.data = null;
      responseData.message = "Something went wrong while fetching the Playerss";
      responseData.type = "error";
    });

  return responseData;
};
