import Amplify, { Storage } from 'aws-amplify';
import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineClose, AiOutlinePlayCircle } from 'react-icons/ai';
import { FiHeadphones } from 'react-icons/fi';
import awsmobile from '../../aws-exports';
import { latestFilteredContent, listLatestContent } from '../api/LatestApi';
import CreateNotifications from '../Notifications';
import ReactModal from 'react-modal';

Amplify.configure(awsmobile);

function HeadStack() {
  const [display, setDisplay] = useState('latest');

  return (
    <section className="headstack-container">
      <div className="headstack-bar">
        <div
          onClick={() => setDisplay('latest')}
          className={`item ${display === 'latest' ? 'item-active' : ''}`}
        >
          Latest
        </div>
        <div
          onClick={() => setDisplay('videos')}
          className={`item ${display === 'videos' ? 'item-active' : ''}`}
        >
          Videos
        </div>
        <div
          onClick={() => setDisplay('podcasts')}
          className={`item ${display === 'podcasts' ? 'item-active' : ''}`}
        >
          Podcasts
        </div>
      </div>
      <div className="display-content">
        {display === 'videos' ? (
          <VideoContent />
        ) : display === 'podcasts' ? (
          <PodcastContent />
        ) : (
          <LatestContent />
        )}
      </div>
    </section>
  );
}

export default HeadStack;

const VideoContent = () => {
  const [data, setData] = useState([]);
  const [link, setLink] = useState('');
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [descp, setDescp] = useState('');

  useEffect(() => {
    let isSubscribed = true;

    const initialLoad = async () => {
      await latestFilteredContent('video')
        .then((res) => {
          if (res.type === 'success') {
            if (!isSubscribed) return;
            setData(res.data.data.listNews.items);
          } else throw res;
        })
        .catch((err) => {
          CreateNotifications('error', err.message);
        });
    };

    initialLoad();

    return () => (isSubscribed = false);
  }, []);
  return (
    <>
      <Video
        link={link}
        open={open}
        title={title}
        descp={descp}
        toClose={() => setOpen(false)}
      />
      <div className="content">
        {data.length ? (
          data.map((item, index) => {
            return (
              <div key={index} className="item">
                <AiOutlinePlayCircle size={18} />
                <p
                  onClick={() => {
                    setLink(item.url);
                    setOpen(true);
                    setDescp(item.description);
                    setTitle(item.title);
                  }}
                >
                  {item.title}
                </p>
              </div>
            );
          })
        ) : (
          <p>Nothing Uploaded Here</p>
        )}
        <div className="more-content">
          <a href="/">More Videos</a>
        </div>
      </div>
    </>
  );
};

const PodcastContent = () => {
  const [data, setData] = useState([]);
  const [link, setLink] = useState('');
  const [title, setTitle] = useState('');
  const [descp, setDescp] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let isSubscribed = true;
    const initialLoad = async () => {
      await latestFilteredContent('podcast')
        .then((res) => {
          if (res.type === 'success') {
            if (!isSubscribed) return;
            setData(res.data.data.listNews.items);
          } else throw res;
        })
        .catch((err) => {
          CreateNotifications('error', err.message);
        });
    };
    initialLoad();

    return () => (isSubscribed = false);
  }, []);
  return (
    <>
      <Audio
        title={title}
        descp={descp}
        open={open}
        toClose={() => setOpen(false)}
        link={link}
      />
      <div className="content">
        {data.length ? (
          data.map((item, index) => {
            return (
              <div key={index} className="item">
                <FiHeadphones size={20} />
                <p
                  onClick={() => {
                    setLink(item.url);
                    setOpen(true);
                    setTitle(item.title);
                    setDescp(item.description);
                  }}
                >
                  {item.title}
                </p>
              </div>
            );
          })
        ) : (
          <p>Nothing Uploaded Here</p>
        )}
        <div className="more-content">
          <a href="/">More Podcasts</a>
        </div>
      </div>
    </>
  );
};

const LatestContent = () => {
  const [data, setData] = useState([]);
  const [link, setLink] = useState('');
  const [title, setTitle] = useState('');
  const [descp, setDescp] = useState('');
  const [type, setType] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let isSubscribed = true;
    const initialLoad = async () => {
      await listLatestContent()
        .then((res) => {
          if (res.type === 'success') {
            if (!isSubscribed) return;
            setData(res.data.data.listNews.items);
          } else throw res;
        })
        .catch((err) => {
          CreateNotifications('error', err.message);
        });
    };
    initialLoad();

    return () => (isSubscribed = false);
  }, []);

  return (
    <>
      {type === 'video' ? (
        <Video
          link={link}
          title={title}
          descp={descp}
          open={open}
          toClose={() => setOpen(false)}
        />
      ) : (
        <Audio
          open={open}
          link={link}
          title={title}
          descp={descp}
          toClose={() => setOpen(false)}
        />
      )}
      <div className="content">
        {data.length ? (
          data
            .sort((a, b) => {
              return new Date(b.updatedAt) - new Date(a.updatedAt);
            })
            .map((item, index) => {
              return (
                <div key={index} className="item">
                  {item.type === 'video' ? (
                    <AiOutlinePlayCircle size={18} />
                  ) : (
                    <FiHeadphones size={20} />
                  )}
                  <p
                    onClick={() => {
                      setOpen(true);
                      setTitle(item.title);
                      setDescp(item.description);
                      setLink(item.url);
                      setType(item.type);
                    }}
                  >
                    {item.title}
                  </p>
                </div>
              );
            })
        ) : (
          <p>Nothing Uploaded Here</p>
        )}

        <div className="more-content">
          <a href="/">More Latest News</a>
        </div>
      </div>
    </>
  );
};

const Audio = ({ link, open, toClose, title, descp }) => {
  const [audio, setAudio] = useState('');
  const [load, setLoad] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    let isSubscribed = true;

    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        toClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    const initialLoad = async () => {
      const url = await Storage.get(link, { expires: 60000 });
      if (!isSubscribed) return;
      setAudio(url);
    };

    initialLoad();

    return () => {
      document.removeEventListener('mousedown', null);
      return (isSubscribed = false);
    };
  }, [link, toClose]);

  return (
    <ReactModal
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      className="resource__player"
      isOpen={open}
    >
      <div className="cover">
        <AiOutlineClose
          color={'#525252'}
          onClick={toClose}
          className="close"
          size={36}
        />

        <div ref={ref} className="resource__container">
          <div className="details">
            <h2>{title}</h2>
            <p>{descp}</p>
          </div>
          <audio
            onLoadStart={() => setLoad(true)}
            onPlay={() => setLoad(false)}
            controls
            autoPlay
            src={audio}
          />
          {load && <p className="load">Loading...</p>}
        </div>
      </div>
    </ReactModal>
  );
};

const Video = ({ link, title, descp, open, toClose }) => {
  const [video, setVideo] = useState('');
  const ref = useRef(null);

  useEffect(() => {
    let isSubscribed = true;

    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        toClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    const initialLoad = async () => {
      const url = await Storage.get(link, { expires: 60000 });
      if (!isSubscribed) return;
      setVideo(url);
    };

    initialLoad();

    return () => {
      document.removeEventListener('mousedown', null);
      return (isSubscribed = false);
    };
  }, [link, toClose]);

  return (
    <ReactModal
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      className="resource__player"
      isOpen={open}
    >
      <div className="cover">
        <AiOutlineClose
          color={'#525252'}
          onClick={toClose}
          className="close"
          size={36}
        />
        <div ref={ref} className="resource__container">
          <div className="details">
            <h2>{title}</h2>
            <p>{descp}</p>
          </div>
          <video controls autoPlay>
            <source src={video} type="video/mp4" />
          </video>
        </div>
      </div>
    </ReactModal>
  );
};
