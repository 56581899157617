import React from 'react';

function FormTextArea({ type = 'text', row = 4, ...otherProps }) {
  return (
    <div>
      <textarea
        style={Styles.inputstyle}
        type={type}
        rows={row}
        {...otherProps}
      />
    </div>
  );
}

const Styles = {
  inputstyle: {
    margin: '5px',
    width: '100%',
    fontSize: '16px',
    border: '1px  solid lightgrey',
    borderRadius: '4px',
    padding: '6px',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
    transition: ' 0.3s',
  },
};

export default FormTextArea;
