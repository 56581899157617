import Amplify, { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import AuthAPI from '../../api/Auth';
import storage from '../../api/storage';

import awsmobile from '../../../aws-exports';

import { Input, Password } from '../../Input';
import Loader from '../../Loader';
import CreateNotifications from '../../Notifications';

Amplify.configure(awsmobile);

function ChangePassword() {
  const [cpass, setCPass] = useState('');
  const [cshow, setCShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [password, setPassword] = useState('');
  const [pshow, setPShow] = useState(false);
  const [username, setUsername] = useState('');
  const [oldPassword, setOPassword] = useState('');
  const [oshow, setOShow] = useState(false);
  const [name, setName] = useState('');

  const history = useHistory();

  useEffect(() => {
    return () => {
      setCPass('');
      setPassword('');
      setUsername('');
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== cpass) {
      CreateNotifications(
        'warning',
        'Password does not match. Please try again'
      );
      return;
    }

    setLoader(true);

    await Auth.signIn(username, oldPassword)
      .then(async (user) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          await Auth.completeNewPassword(user, password, { name: name })
            .then(async (res) => {
              const cred = await AuthAPI.currentCredentials();
              storage.storeToken(cred.data);
              history.push('/admin');
            })
            .catch((err) => {
              CreateNotifications('error', err.message);
              // alert('Sign in failed try signing in.');
              history.push('/admin/login');
            });
        } else {
          const cred = await AuthAPI.currentCredentials();
          storage.storeToken(cred.data);
          history.push('/admin');
        }
      })
      .catch((err) => {
        CreateNotifications('error', err.message);
        // alert('Sign in failed try signing in.');
        history.push('/admin/login');
      });
    setLoader(false);
  };

  return (
    <main className="centre_content">
      <Loader show={loader} />
      <section className="header">
        <div className="header_title">
          <h3>757 Next Level</h3>
        </div>
        <div className="header_content">
          <h1>Welcome New User</h1>
          <h3>Please change your password</h3>
        </div>
      </section>
      <section className="form_container">
        <div className="form">
          <form onSubmit={handleSubmit}>
            <Input
              type="text"
              placeholder="Enter name"
              autoComplete={'true'}
              autoFocus
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              type="text"
              placeholder="Enter username"
              autoComplete={'true'}
              autoFocus
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <Password
              type={oshow ? 'text' : 'password'}
              placeholder="Enter old password"
              autoComplete={'true'}
              autoFocus
              required
              value={oldPassword}
              setShow={() => setOShow(!pshow)}
              onChange={(e) => setOPassword(e.target.value)}
            />
            <Password
              type={pshow ? 'text' : 'password'}
              placeholder="Enter new password"
              autoComplete={'true'}
              autoFocus
              required
              value={password}
              setShow={() => setPShow(!pshow)}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Password
              type={cshow ? 'text' : 'password'}
              placeholder="Confirm Password"
              autoComplete={'true'}
              autoFocus
              required
              value={cpass}
              setShow={() => setCShow(!cshow)}
              onChange={(e) => setCPass(e.target.value)}
            />
            <button className="form_submit_button" type="submit">
              Change Password
            </button>
          </form>
        </div>
      </section>
      <section className="btn_row_container">
        <div className="btn_row sign_in">
          <button onClick={() => history.push('/admin/login')} className="btn">
            Back to Sign In
          </button>
        </div>
      </section>
    </main>
  );
}

export default ChangePassword;
