import { Storage } from 'aws-amplify';
import React, { useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { updateLatestContent } from './api/LatestApi';
import Loader from './Loader';
import CreateNotifications from './Notifications';

function EditContent({ show, content, setShow }) {
  const [title, setTitle] = useState(content.title);
  const [descp, setDescp] = useState(content.description);
  const [loading, setLoading] = useState(false);

  const inputRef = useRef(null);

  const handleUpdate = async (e) => {
    e.preventDefault();

    setLoading(true);

    const blob = inputRef.current.files[0];
    const filename =
      content.type === 'video' ? `${title}-video.mp4` : `${title}-podcasts.mp3`;

    blob &&
      (await Storage.put(filename, blob)
        .then((res) => {
          CreateNotifications(
            'success',
            `Successfully Uploaded the ${content.type}`
          );
        })
        .catch((err) => {
          CreateNotifications(
            'error',
            'Something went wrong while uploading the file.'
          );
          return;
        }));

    const input = {
      id: content.id,
      title: title,
      description: descp,
      url: blob ? filename : content.url,
    };

    await updateLatestContent(input)
      .then((res) => {
        if (res.type === 'success') {
          CreateNotifications('success', res.message);
        } else throw res;
      })
      .catch((err) => {
        CreateNotifications('error', err.message);
      });

    setTitle('');
    setDescp('');
    setLoading(false);
    setShow(false);
  };

  return (
    <ReactModal isOpen={show}>
      <Loader show={loading} />
      <section className="headstack_details">
        <div className="header">
          <h1>Videos</h1>
          <h3>Add your videos</h3>
        </div>
        <div className="headstack_form_container">
          <form onSubmit={(e) => handleUpdate(e)}>
            <div className="input">
              <label>Title</label>
              <input
                required
                type="text"
                autoFocus
                placeholder="Enter the title"
                value={title}
                autoCapitalize="true"
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="input">
              <label>Description</label>
              <textarea
                autoCapitalize="true"
                value={descp}
                onChange={(e) => setDescp(e.target.value)}
                autoCorrect="true"
                placeholder="Enter description"
              />
            </div>
            <div className="input">
              <label>Video (current: {`${content.url}`}) </label>
              <input
                placeholder={`${content.file}`}
                ref={inputRef}
                type="file"
                value={content.file}
                accept={content.type === 'video' ? 'video/*' : '.mp3,audio/*'}
              />
            </div>
            <div className="form__btn_container">
              <button type="submit" className="form_submit_button ">
                Submit
              </button>
              <button
                onClick={() => {
                  setDescp('');
                  setTitle('');
                  setShow(false);
                }}
                className="form_submit_button cancel"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </section>
    </ReactModal>
  );
}

export default EditContent;
