import React, { useEffect } from "react";
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
import "./styles/Dashboard.css";
import { useHistory } from "react-router-dom";
import AuthAPI from "../../api/Auth";
import CreateNotifications from "../../Notifications";
import { Image } from "react-bootstrap";
import Logo from "../../../assets/images/logo.png";
import { BrowserView, MobileView } from "react-device-detect";

function PlayerSidebar(props) {
  const history = useHistory();

  useEffect(() => {
    history.location.pathname.includes("/profile");
  });

  const logOut = async (e) => {
    e.preventDefault();
    var res = await AuthAPI.signOut();
    console.log(res);
    history.push("/player");
    CreateNotifications("success", "Log Out Successfull");
  };

  return (
    <>
      <MobileView>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <Container>
            <Navbar.Brand href="#home">757 Next Level</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Item>
                  <Nav.Link href="/player/dashboard" eventKey="dashboard">
                    Dashboard
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/player/profile" eventKey="profile">
                    Profile
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    onClick={(e) => {
                      logOut(e);
                    }}
                    beventKey="disabled"
                  >
                    Logout
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </MobileView>
      <div>
        <Nav
          className="col-md-2 d-none d-md-block bg-light sidebar"
          variant="tabs"
          defaultActiveKey={
            history.location.pathname.includes("/profile")
              ? "profile"
              : history.location.pathname.includes("/updateProfile")
              ? "profile"
              : history.location.pathname.includes("/dashboard")
              ? "dashboard"
              : "null"
          }
        >
          <Image
            src={Logo}
            thumbnail
            style={{ marginBottom: "20px", backgroundColor: "darkgrey" }}
          />

          <div className="sidebar-sticky"></div>
          <Nav.Item>
            <Nav.Link href="/player/dashboard" eventKey="dashboard">
              Dashboard
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/player/profile" eventKey="profile">
              Profile
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              onClick={(e) => {
                logOut(e);
              }}
              beventKey="disabled"
            >
              Logout
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
    </>
  );
}

export default PlayerSidebar;
