import React from 'react';
import Loaders from 'react-loader-spinner';
import ReactModal from 'react-modal';

ReactModal.setAppElement('#root');

function Loader({ show }) {
  return (
    <>
      <ReactModal className="loader" isOpen={show}>
        <Loaders
          type="Puff"
          color="#e1cff3"
          height={80}
          width={80}
          visible={true}
        />
      </ReactModal>
    </>
  );
}

export default Loader;
