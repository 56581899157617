import React, { useEffect, useState, useRef } from "react";
import PlayerSidebarWrapper from "./PlayerSidebarWrapper";
// import Modal from "react-modal";
import ImageCropper from "../../ImageCropper";
import { useHistory } from "react-router";
import Amplify, { Storage } from "aws-amplify";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Image,
  Modal,
} from "react-bootstrap";
import { getUserByEmail } from "../../api/User";
import { updateUserFunction } from "../../api/User";
import Loader from "../../Loader";
import CreateNotifications from "../../Notifications";
import AuthAPI from "../../api/Auth";

function PlayerUpdateProfile(props) {
  const history = useHistory();
  const [imgsrc, setimagesrc] = useState("");
  const [profileS3URL, setprofileS3URL] = useState(
    "https://www.pngitem.com/pimgs/m/35-350426_profile-icon-png-default-profile-picture-png-transparent.png"
  );
  const [profileImageKey, setprofileImageKey] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [imgName, setImgName] = useState("");

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [profilePic, setprofilePic] = useState("");
  const [tshirtSize, setTshirtSize] = useState("");
  const [userdata, setuserdata] = useState("");
  const [loader, setLoader] = useState(true);
  const [field, setField] = useState([]);
  const [schoolGrade, setschoolGrade] = useState();

  useEffect(async () => {
    try {
      var res = await AuthAPI.currentAuthenticatedUser();
      setEmail(res.data.attributes.email);
      var res = await getUserByEmail(res.data.attributes.email);
      // var res = await getUserByEmail("sauravkumar329@gmail.com");

      var user = res.data.data.SearchByEmail.items[0];
      console.log(user);
      setuserdata(user);
      setEmail(user.email);
      setName(user.name);
      setGender(user.gender);
      setprofileImageKey(user.profilePic);
      setTshirtSize(user.shirtSize);
      setschoolGrade(user.schoolGrade);

      if (user.profilePic != null) {
        const signedURL = await Storage.get(user.profilePic, {
          expires: 60000,
        });
        setprofileS3URL(signedURL);
      }
      setLoader(false);
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  }, []);

  const imageUpload = async () => {
    console.log("in image update");
    if (!profileImageKey && imageUrl) {
      // var current_email = resp.data.attributes.email;
      var filename = userdata.id;
      setprofileImageKey(filename);
      const result = await Storage.put(filename, imageUrl);
      console.log(result);
    } else if (imageUrl && profileImageKey) {
      var filename = userdata.id;
      setprofileImageKey(filename);
      const result = await Storage.put(filename, imageUrl);
      console.log(result, filename, profileS3URL, imageUrl);
    } else {
      // const result = await Storage.put(profileImageKey, imageUrl);
      console.log("nothing to update in image");
    }
  };

  const imageName = (image) => {
    console.log(imageUrl);
    setImgName(image);
  };

  const updateUser = async (e) => {
    setLoader(true);
    e.preventDefault();
    await imageUpload();

    var input = {
      id: userdata.id,
      email: email,
      name: name,
      gender: gender,
      shirtSize: tshirtSize,
      schoolGrade: schoolGrade,
      profilePic: imgsrc != "" ? userdata.id : profileImageKey,
    };
    console.log(input, profileImageKey, userdata.id, imgsrc);
    try {
      var res = await updateUserFunction(input);
      console.log(res);
      CreateNotifications("success", "Player Profile Successfully Updated");
      setLoader(false);
    } catch (err) {
      CreateNotifications(
        "error",
        "Something went wrong while Updating Player"
      );
      setLoader(false);
    }
  };
  return (
    <PlayerSidebarWrapper>
      <Loader show={loader} />
      <Row
        className="justify-content-md-center"
        style={{ marginTop: "80px", marginBottom: "50px" }}
      >
        <Col md={6}>
          <div className="text-center">
            <h3>Player Profile</h3>
          </div>

          <Row>
            <Col xs={12} md={12}>
              <div className="text-center">
                <Image
                  src={imgsrc == "" ? profileS3URL : imgsrc}
                  thumbnail
                  style={{ objectFit: "cover", marginTop: "20px" }}
                  height="200"
                  width="200"
                  // alt={imageName}
                />
              </div>
              <div className="text-center" style={{ margin: "10px" }}>
                <i
                  class="fa fa-pencil fa-lg"
                  style={{ marginRight: "20px" }}
                  onClick={() => setIsOpen(true)}
                ></i>
                <i
                  class="fa fa-trash fa-lg"
                  onClick={() => {
                    setprofileImageKey(null);
                    setprofileS3URL(
                      "https://www.pngitem.com/pimgs/m/35-350426_profile-icon-png-default-profile-picture-png-transparent.png"
                    );
                  }}
                ></i>
              </div>
            </Col>
          </Row>
          <Modal
            show={isOpen}
            fullscreen={true}
            onHide={() => setIsOpen(false)}
          >
            <Modal.Body>
              {" "}
              <ImageCropper
                setImage={setImageUrl}
                imageName={imageName}
                aspectRatio={1 / 1}
                close={() => setIsOpen(false)}
                src={setimagesrc}
              />
            </Modal.Body>
          </Modal>

          <Row
            className="justify-content-md-center"
            style={{ marginTop: "80px" }}
          >
            <Col md={9}>
              <Form onSubmit={updateUser}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextEmail"
                >
                  <Form.Label column sm="3">
                    Name
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextEmail"
                >
                  <Form.Label column sm="3">
                    Email
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control disabled={true} value={email} />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextEmail"
                >
                  <Form.Label column sm="3">
                    Gender
                  </Form.Label>
                  <Col sm="9">
                    <Form.Select
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <option value="rather not say">Rather Not Say</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextEmail"
                >
                  <Form.Label column sm="3">
                    Shirt Size
                  </Form.Label>
                  <Col sm="9">
                    <Form.Select
                      value={tshirtSize}
                      onChange={(e) => setTshirtSize(e.target.value)}
                    >
                      <option value="Kids Small">Kids Small</option>
                      <option value="Kids Medium">Kids Medium</option>
                      <option value="Kids Large">Kids Large</option>
                      <option value="Adult Small">Adult Small</option>
                      <option value="Adult Medium">Adult Medium</option>
                      <option value="Adult Large">Adult Large</option>
                      <option value="Adult XL">Adult XL</option>
                      <option value="Adult XXL">Adult XXL</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextEmail"
                >
                  <Form.Label column sm="3">
                    School Grade
                  </Form.Label>
                  <Col sm="9">
                    <Form.Select
                      value={schoolGrade}
                      onChange={(e) => setschoolGrade(e.target.value)}
                    >
                      <option value="K">K</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
                <Button
                  type="submit"
                  style={{ marginRight: "10px" }}
                  variant="outline-primary"
                >
                  UPDATE
                </Button>
                <Button
                  onClick={() => history.goBack()}
                  variant="outline-danger"
                >
                  BACK
                </Button>{" "}
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </PlayerSidebarWrapper>
  );
}

export default PlayerUpdateProfile;
