import React, { useEffect, useState } from "react";
import AppButton from "./AppButton";
import ConfirmDelete from "./ConfirmDelete";
import Amplify, { Storage } from "aws-amplify";
import awsmobile from "../aws-exports";

Amplify.configure(awsmobile);

function Card({
  data,
  onClick = null,
  handleDelete = null,
  handleUpdate = null,
  setView = null,
}) {
  const [open, setOpen] = useState(false);
  const [imgUrl, setImgUrl] = useState();
  const DeleteRegistration = () => {
    handleDelete(data.id);
    setOpen(false);
  };

  useEffect(() => {
    getImage();
  }, []);

  const getImage = async () => {
    const imageUrl = await Storage.get(data.eventBanner, { expires: 60000 });
    setImgUrl(imageUrl);
    // return imageUrl;
    // console.log(imageUrl);
  };
  return (
    <>
      <ConfirmDelete
        show={open}
        deleteAction={DeleteRegistration}
        setToDelete={() => setOpen(false)}
      />
      {data ? (
        <div
          onClick={
            onClick
              ? () => onClick(data)
              : () => {
                  console.error("Something went wrong");
                }
          }
          className="card"
        >
          <div style={Styles.container}>
            <h5 style={Styles.title}>{data.title ? data.title : ""}</h5>
            <p style={Styles.subtitle}>
              {data.description ? data.description : ""}
            </p>
            <br></br>
            <img style={Styles.cardImage} src={imgUrl} />
            <h5 style={Styles.subtitle}>
              No of Attachments:{" "}
              {data.attachments
                ? JSON.parse(data.attachments).files.filter(
                    (item) => item.file !== null
                  ).length
                : 0}
            </h5>
            <div style={Styles.buttonContainer}>
              {setView && (
                <AppButton
                  title="View"
                  onClick={() => setView(true)}
                  buttoncolor={"blue"}
                />
              )}
              {handleUpdate && (
                <AppButton
                  title={"Update"}
                  onClick={() => {
                    handleUpdate(data);
                  }}
                  buttoncolor={"green"}
                />
              )}

              {handleDelete && (
                <AppButton
                  onClick={() => setOpen(true)}
                  title={"Delete"}
                  buttoncolor={"red"}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <p>No information available</p>
      )}
    </>
  );
}

const Styles = {
  container: {
    padding: "8px",
  },
  title: {
    fontSize: "18px",
    fontWeight: "500",
  },
  subtitle: {
    fontSize: "14px",
    fontWeight: "300",
  },
  buttonContainer: {
    justifyContent: "space-between",
    marginTop: "10px",
    display: "flex",
  },
  cardImage: {
    width: "100%",
    height: "200px",
    objectFit: "cover",
    borderRadius: "10px",
  },
};

export default Card;
