import React, { useState } from "react";
import { useHistory } from "react-router";
import AuthAPI from "../../api/Auth";
import storage from "../../api/storage";
import { Input, Password } from "../../Input";
import Loader from "../../Loader";
import { IoIosAdd } from "react-icons/io";
import { AiOutlineWarning } from "react-icons/ai";
import Amplify, { Auth } from "aws-amplify";
import awsmobile from "../../../aws-exports";
import CreateNotifications from "../../Notifications";
import Logo from "../../../assets/images/logo.png";

Amplify.configure(awsmobile);

function Login() {
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoader(true);

    await Auth.signIn(username, password)
      .then(async (user) => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          history.push("/admin/register");
        } else {
          const cred = await AuthAPI.currentCredentials();
          if (cred.type === "success") {
            await storage.storeToken(cred.data);
            history.push("/admin/home");
          } else throw cred;
        }
      })
      .catch((err) => {
        alert(err.message);
        CreateNotifications("error", err.message);
      });

    // setPassword('');
    // setUsername('');
    setLoader(false);
  };

  return (
    <main className="bg-flash">
      <section className="centre_content">
        <Loader show={loader} />
        <section className="header">
          <div className="header_title">
            <div className="image" onClick={() => history.push("/")}>
              <img src={Logo} alt="757 Next Level" />
            </div>
          </div>
          <div className="header_content">
            <h1>Welcome to Your Dashbboard</h1>
            <h3>Please Sign in</h3>
          </div>
        </section>
        <section className="form_container">
          <div className="form">
            <form onSubmit={handleLogin}>
              <Input
                type="text"
                placeholder="Enter username"
                autoComplete={"true"}
                autoFocus
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <Password
                type={show ? "text" : "password"}
                placeholder="Enter password"
                autoComplete={"true"}
                value={password}
                required
                onChange={(e) => setPassword(e.target.value)}
                show={show}
                setShow={() => setShow(!show)}
              />

              <button className="form_submit_button" type="submit">
                Login
              </button>
            </form>
          </div>
        </section>
        <section className="btn_row_container">
          <div className="btn_row">
            <IoIosAdd color="#6C757D" size={24} />
            <button
              onClick={() => history.push("/admin/register")}
              className="btn create_account"
            >
              New User
            </button>
          </div>
          <div className="btn_row">
            <AiOutlineWarning color="#6C757D" size={24} />
            <button
              onClick={() => history.push("/admin/reset")}
              className="btn forgot_password"
            >
              Forgot Password
            </button>
          </div>
        </section>
      </section>
    </main>
  );
}

export default Login;
