import React, { useEffect, useState } from "react";
import AppButton from "./AppButton";
import ConfirmDelete from "./ConfirmDelete";
import Amplify, { Storage } from "aws-amplify";
import awsmobile from "../aws-exports";
import { updateTeamFunction } from "./api/Teams";

function TeamCard({
  teams,
  onClick = null,
  deleteHandler = null,
  updateHandler = null,
  setView = null,
}) {
  const [open, setOpen] = useState(false);
  const DeleteTeam = () => {
    deleteHandler(teams.id);
    setOpen(false);
  };

  return (
    <>
      <ConfirmDelete
        show={open}
        deleteAction={DeleteTeam}
        setToDelete={() => setOpen(false)}
      />
      {teams ? (
        <div
          onClick={
            onClick
              ? () => onClick(teams)
              : () => {
                  console.error("Something went wrong");
                }
          }
          className="card"
        >
          <div style={Styles.container}>
            <h5 style={Styles.title}>Team Name: {teams.Name}</h5>
            <h6 style={Styles.subtitle}>Location: {teams.Location}</h6>
            <h5 style={Styles.subtitle}>Coach Name: {teams.CoachName}</h5>
            <h5 style={Styles.subtitle}>
              No of Players: {teams.Players.items.length}
            </h5>
            <div style={Styles.buttonContainer}>
              {setView && (
                <AppButton
                  title="View"
                  onClick={() => setView(true)}
                  buttoncolor={"blue"}
                />
              )}
              {updateHandler && (
                <AppButton
                  title={"Update"}
                  onClick={() => {
                    updateHandler(teams);
                  }}
                  buttoncolor={"green"}
                />
              )}

              {deleteHandler && (
                <AppButton
                  onClick={() => setOpen(true)}
                  title={"Delete"}
                  buttoncolor={"red"}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <p>No Teams available</p>
      )}
    </>
  );
}
const Styles = {
  container: {
    padding: "10px",
  },
  title: {
    fontSize: "18px",
    fontWeight: "600",
  },
  subtitle: {
    fontSize: "14px",
    fontWeight: "400",
  },
  buttonContainer: {
    justifyContent: "space-between",
    marginTop: "20px",
    display: "flex",
  },
};

export default TeamCard;
