import React from 'react';
import Drawer from './Drawer';

function ScreenWrapper(props) {
  return (
    <main className="screen">
      <Drawer />
      <section className="wrapper">{props.children}</section>
    </main>
  );
}

export default ScreenWrapper;
