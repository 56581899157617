import React, { useEffect, useState, useRef } from "react";
import PlayerSidebarWrapper from "./PlayerSidebarWrapper";
import Amplify, { Storage } from "aws-amplify";
import ImageCropper from "../../ImageCropper";
import { useHistory } from "react-router";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Image,
  Modal,
} from "react-bootstrap";
import { getUserByEmail } from "../../api/User";
import Loader from "../../Loader";
import AuthAPI from "../../api/Auth";

function PlayerProfile(props) {
  const history = useHistory();
  const [imgsrc, setimagesrc] = useState("");
  const [profileS3URL, setprofileS3URL] = useState(
    "https://www.pngitem.com/pimgs/m/35-350426_profile-icon-png-default-profile-picture-png-transparent.png"
  );
  const [profileImageKey, setprofileImageKey] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [imgName, setImgName] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [profilePic, setprofilePic] = useState("");
  const [userdata, setuserdata] = useState("");
  const [loader, setLoader] = useState(true);
  const [shirtSize, setTshirtSize] = useState();
  const [schoolGrade, setschoolGrade] = useState();

  useEffect(async () => {
    try {
      var res = await AuthAPI.currentAuthenticatedUser();
      setEmail(res.data.attributes.email);
      var res = await getUserByEmail(res.data.attributes.email);

      var user = res.data.data.SearchByEmail.items[0];
      setuserdata(user);
      setEmail(user.email);
      setName(user.name);
      setGender(user.gender);
      setprofileImageKey(user.profilePic);
      setTshirtSize(user.shirtSize);
      setschoolGrade(user.schoolGrade);
      if (user.profilePic != null) {
        const signedURL = await Storage.get(user.profilePic, {
          expires: 60000,
        });
        setprofileS3URL(signedURL);
      }
      setLoader(false);
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  }, []);

  // if (imageUrl) {
  //   const result = await Storage.put(filename, imageUrl);
  //   console.log(result);
  // }
  const imageName = (image) => {
    console.log(imageUrl);
    setImgName(image);
  };

  return (
    <PlayerSidebarWrapper>
      <Loader show={loader} />
      <Row
        className="justify-content-md-center"
        style={{ marginTop: "80px", marginBottom: "50px" }}
      >
        <Col md={6}>
          <div className="text-center">
            <h3>Player Profile</h3>
          </div>

          <Row>
            <Col xs={12} md={12}>
              <div className="text-center">
                <Image
                  src={imgsrc == "" ? profileS3URL : imgsrc}
                  thumbnail
                  style={{ objectFit: "cover", marginTop: "20px" }}
                  height="200"
                  width="200"
                  alt={"ProfileImage"}
                />
              </div>
            </Col>
          </Row>
          <Modal
            show={isOpen}
            fullscreen={true}
            onHide={() => setIsOpen(false)}
          >
            <Modal.Body>
              {" "}
              <ImageCropper
                setImage={setImageUrl}
                imageName={imageName}
                aspectRatio={1 / 1}
                close={() => setIsOpen(false)}
                src={setimagesrc}
              />
            </Modal.Body>
          </Modal>

          <Row
            className="justify-content-md-center"
            style={{ marginTop: "80px" }}
          >
            <Col md={8}>
              <Form>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextEmail"
                >
                  <Form.Label column sm="3">
                    Name
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control plaintext readOnly defaultValue={name} />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextEmail"
                >
                  <Form.Label column sm="3">
                    Gender
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control plaintext readOnly defaultValue={gender} />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextEmail"
                >
                  <Form.Label column sm="3">
                    Email
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control plaintext readOnly defaultValue={email} />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextEmail"
                >
                  <Form.Label column sm="3">
                    Shirt Size
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control plaintext readOnly defaultValue={shirtSize} />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextEmail"
                >
                  <Form.Label column sm="3">
                    School Grade
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      plaintext
                      readOnly
                      defaultValue={schoolGrade}
                    />
                  </Col>
                </Form.Group>
                <Button
                  onClick={() => history.push("/player/updateProfile")}
                  variant="outline-primary"
                >
                  EDIT
                </Button>{" "}
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </PlayerSidebarWrapper>
  );
}

export default PlayerProfile;
