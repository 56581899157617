import { Storage } from "aws-amplify";
import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { BsPencilSquare, BsFillTrashFill } from "react-icons/bs";
import {
  createLatestContent,
  deleteLatestContent,
  latestFilteredContent,
} from "../api/LatestApi";
import ConfirmDelete from "../ConfirmDelete";
import EditContent from "../EditContent";
import Loader from "../Loader";
import CreateNotifications from "../Notifications";
import ScreenWrapper from "../ScreenWrapper";

function Podcasts() {
  const [title, setTitle] = useState("");
  const [descp, setDescp] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [content, setContent] = useState();
  const [toDelete, setToDelete] = useState(false);

  const podcastInput = useRef(null);
  const isSubscribed = useRef(true);

  useEffect(() => {
    initialLoad();

    return () => (isSubscribed.current = false);
  }, []);

  const initialLoad = async () => {
    await latestFilteredContent("podcast")
      .then((res) => {
        if (!isSubscribed.current) return;
        if (res.type === "success") {
          setData(res.data.data.listNews.items);
        } else throw res;
      })
      .catch((err) => {
        CreateNotifications("error", err.message);
      });
  };

  const columns = [
    {
      name: "Title",
      selector: (row) => row["title"],
      sortable: true,
      style: { fontSize: "1rem", cursor: "pointer" },
    },
    {
      name: "File",
      selector: (row) => row["url"],
      sortable: false,
      left: true,
      style: { fontSize: "1rem", cursor: "pointer" },
    },
    {
      name: "Created Date",
      selector: (row) => new Date(row["createdAt"]).toLocaleDateString(),
      sortable: true,
      left: true,
      style: { fontSize: "1rem", cursor: "pointer" },
    },
    {
      cell: (cellData) => {
        return (
          <div id="data-table-btn-container">
            <button
              onClick={() => {
                setShow(true);
                setContent(cellData);
              }}
              id="data-table-btn"
            >
              <BsPencilSquare color="#1653fd" />
            </button>
            <button
              onClick={() => handleDelete(cellData.id)}
              id="data-table-btn"
            >
              <BsFillTrashFill color="tomato" />
            </button>
          </div>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();
    const blob = podcastInput.current.files[0];
    const podcastName = `${title}-podcast.mp3`;

    await Storage.put(podcastName, blob)
      .then((data) => {})
      .catch((err) => {
        CreateNotifications("error", err.message);
      });

    const input = {
      type: "podcast",
      title: title,
      description: descp,
      url: podcastName,
    };

    await createLatestContent(input)
      .then((res) => {
        if (res.type === "success") {
          CreateNotifications("success", res.message);
        } else throw res;
      })
      .catch((err) => {
        CreateNotifications("error", err.message);
      });

    setTitle("");
    setDescp("");
    setLoading(false);
    initialLoad();
  };

  const handleDelete = async (id) => {
    await deleteLatestContent(id)
      .then(async (res) => {
        if (res.type === "success") {
          await Storage.remove(res.data.data.deleteNews.url);
          CreateNotifications("success", res.message);
        } else throw res;
      })
      .catch((err) => {
        CreateNotifications("error", err.message);
      });

    setToDelete(false);
    initialLoad();
  };

  return (
    <ScreenWrapper>
      <Loader show={loading} />
      {show && <EditContent show={show} content={content} setShow={setShow} />}
      {toDelete && (
        <ConfirmDelete
          show={toDelete}
          deleteAction={handleDelete}
          setToDelete={() => setToDelete(false)}
        />
      )}
      <section className="headstack_details">
        <div className="header">
          <h1>Podcasts</h1>
          <h3>Add your podcasts</h3>
        </div>
        <div className="headstack_form_container">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="input">
              <label>Title</label>
              <input
                required
                type="text"
                autoFocus
                placeholder="Enter the title"
                value={title}
                autoCapitalize="true"
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="input">
              <label>Description</label>
              <textarea
                autoCapitalize="true"
                value={descp}
                onChange={(e) => setDescp(e.target.value)}
                autoCorrect="true"
                placeholder="Enter description"
              />
            </div>
            <div className="input">
              <label>Podcast</label>
              <input
                required
                ref={podcastInput}
                type="file"
                accept=".mp3,audio/*"
              />
            </div>

            <div className="form__btn_container">
              <button type="submit" className="form_submit_button ">
                Submit
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setTitle("");
                  setDescp("");
                }}
                className="form_submit_button cancel"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
        <div className="headstack_data_table">
          <DataTable
            title="Podcasts List"
            defaultSortField="title"
            defaultSortAsc={true}
            columns={columns}
            data={data}
            pagination={true}
            paginationRowsPerPageOptions={[5, 10, 15]}
            striped
          />
        </div>
      </section>
    </ScreenWrapper>
  );
}

export default Podcasts;
