/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPlayers = /* GraphQL */ `
  mutation CreatePlayers(
    $input: CreatePlayersInput!
    $condition: ModelPlayersConditionInput
  ) {
    createPlayers(input: $input, condition: $condition) {
      id
      Name
      Gender
      Age
      TeamName
      teamID
      ProfilePicture
      createdAt
      updatedAt
    }
  }
`;
export const updatePlayers = /* GraphQL */ `
  mutation UpdatePlayers(
    $input: UpdatePlayersInput!
    $condition: ModelPlayersConditionInput
  ) {
    updatePlayers(input: $input, condition: $condition) {
      id
      Name
      Gender
      Age
      TeamName
      teamID
      ProfilePicture
      createdAt
      updatedAt
    }
  }
`;
export const deletePlayers = /* GraphQL */ `
  mutation DeletePlayers(
    $input: DeletePlayersInput!
    $condition: ModelPlayersConditionInput
  ) {
    deletePlayers(input: $input, condition: $condition) {
      id
      Name
      Gender
      Age
      TeamName
      teamID
      ProfilePicture
      createdAt
      updatedAt
    }
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      Name
      Location
      CoachName
      createdAt
      updatedAt
      Players {
        items {
          id
          Name
          Gender
          Age
          TeamName
          teamID
          ProfilePicture
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      Name
      Location
      CoachName
      createdAt
      updatedAt
      Players {
        items {
          id
          Name
          Gender
          Age
          TeamName
          teamID
          ProfilePicture
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
      Name
      Location
      CoachName
      createdAt
      updatedAt
      Players {
        items {
          id
          Name
          Gender
          Age
          TeamName
          teamID
          ProfilePicture
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createRegistrations = /* GraphQL */ `
  mutation CreateRegistrations(
    $input: CreateRegistrationsInput!
    $condition: ModelRegistrationsConditionInput
  ) {
    createRegistrations(input: $input, condition: $condition) {
      id
      title
      description
      attachments
      contact
      startdate
      enddate
      eventBanner
      createdAt
      updatedAt
    }
  }
`;
export const updateRegistrations = /* GraphQL */ `
  mutation UpdateRegistrations(
    $input: UpdateRegistrationsInput!
    $condition: ModelRegistrationsConditionInput
  ) {
    updateRegistrations(input: $input, condition: $condition) {
      id
      title
      description
      attachments
      contact
      startdate
      enddate
      eventBanner
      createdAt
      updatedAt
    }
  }
`;
export const deleteRegistrations = /* GraphQL */ `
  mutation DeleteRegistrations(
    $input: DeleteRegistrationsInput!
    $condition: ModelRegistrationsConditionInput
  ) {
    deleteRegistrations(input: $input, condition: $condition) {
      id
      title
      description
      attachments
      contact
      startdate
      enddate
      eventBanner
      createdAt
      updatedAt
    }
  }
`;
export const createLandingPage = /* GraphQL */ `
  mutation CreateLandingPage(
    $input: CreateLandingPageInput!
    $condition: ModelLandingPageConditionInput
  ) {
    createLandingPage(input: $input, condition: $condition) {
      id
      banner_img
      carousel
      socialMedia
      createdAt
      updatedAt
    }
  }
`;
export const updateLandingPage = /* GraphQL */ `
  mutation UpdateLandingPage(
    $input: UpdateLandingPageInput!
    $condition: ModelLandingPageConditionInput
  ) {
    updateLandingPage(input: $input, condition: $condition) {
      id
      banner_img
      carousel
      socialMedia
      createdAt
      updatedAt
    }
  }
`;
export const deleteLandingPage = /* GraphQL */ `
  mutation DeleteLandingPage(
    $input: DeleteLandingPageInput!
    $condition: ModelLandingPageConditionInput
  ) {
    deleteLandingPage(input: $input, condition: $condition) {
      id
      banner_img
      carousel
      socialMedia
      createdAt
      updatedAt
    }
  }
`;
export const createNews = /* GraphQL */ `
  mutation CreateNews(
    $input: CreateNewsInput!
    $condition: ModelNewsConditionInput
  ) {
    createNews(input: $input, condition: $condition) {
      id
      type
      title
      description
      url
      createdAt
      updatedAt
    }
  }
`;
export const updateNews = /* GraphQL */ `
  mutation UpdateNews(
    $input: UpdateNewsInput!
    $condition: ModelNewsConditionInput
  ) {
    updateNews(input: $input, condition: $condition) {
      id
      type
      title
      description
      url
      createdAt
      updatedAt
    }
  }
`;
export const deleteNews = /* GraphQL */ `
  mutation DeleteNews(
    $input: DeleteNewsInput!
    $condition: ModelNewsConditionInput
  ) {
    deleteNews(input: $input, condition: $condition) {
      id
      type
      title
      description
      url
      createdAt
      updatedAt
    }
  }
`;
export const createLogs = /* GraphQL */ `
  mutation CreateLogs(
    $input: CreateLogsInput!
    $condition: ModelLogsConditionInput
  ) {
    createLogs(input: $input, condition: $condition) {
      id
      username
      searchimageName
      countResults
      createdAt
      updatedAt
    }
  }
`;
export const updateLogs = /* GraphQL */ `
  mutation UpdateLogs(
    $input: UpdateLogsInput!
    $condition: ModelLogsConditionInput
  ) {
    updateLogs(input: $input, condition: $condition) {
      id
      username
      searchimageName
      countResults
      createdAt
      updatedAt
    }
  }
`;
export const deleteLogs = /* GraphQL */ `
  mutation DeleteLogs(
    $input: DeleteLogsInput!
    $condition: ModelLogsConditionInput
  ) {
    deleteLogs(input: $input, condition: $condition) {
      id
      username
      searchimageName
      countResults
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      email
      profilePic
      gender
      role
      shirtSize
      schoolSystem
      DashboardPictureCollection
      FutureSeasonsToPlay
      CoachTeamPreference
      schoolGrade
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      email
      profilePic
      gender
      role
      shirtSize
      schoolSystem
      DashboardPictureCollection
      FutureSeasonsToPlay
      CoachTeamPreference
      schoolGrade
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      email
      profilePic
      gender
      role
      shirtSize
      schoolSystem
      DashboardPictureCollection
      FutureSeasonsToPlay
      CoachTeamPreference
      schoolGrade
      createdAt
      updatedAt
    }
  }
`;
