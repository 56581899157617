import Amplify, { API } from "aws-amplify";
import awsmobile from "../../aws-exports";

Amplify.configure(awsmobile);

let responseData = {
  type: "error",
  message: "API Not Called",
  data: null,
};

export const getSignedUrls = async (input) => {
  await API.post("nextlevel757AI", "/getsignedurls", {
    body: {
      keys: input,
    },
  })
    .then((response) => {
      responseData.data = response;
      responseData.message = "Successfully added the content.";
      responseData.type = "success";
      //   console.log("res", response);
      // Add your code here
    })
    .catch((error) => {
      console.log(error);
      responseData.data = null;
      responseData.message = "Something went wrong while calling the content";
      responseData.type = "error";
    });
  return responseData;
};

export const getImagesByFaces = async (input) => {
  console.log("api called", input);
  await API.post("nextlevel757AI", "/searchImageByFace", {
    body: { key: input.key, c_id: input.c_id },
  })
    .then((response) => {
      responseData.data = response;
      responseData.message = "Successfully added the content.";
      responseData.type = "success";
      console.log("res", response);
      // Add your code here
    })
    .catch((error) => {
      console.log(error);
      responseData.data = null;
      responseData.message = "Something went wrong while calling the content";
      responseData.type = "error";
    });
  return responseData;
};
