import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import AuthAPI from '../../api/Auth';
import storage from '../../api/storage';
import { Input, Password } from '../../Input';
import Loader from '../../Loader';
import CreateNotifications from '../../Notifications';

function ResetPassword() {
  const [username, setUsername] = useState('');
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState('');
  const [cpass, setCPass] = useState('');
  const [code, setCode] = useState('');
  const [pshow, setPShow] = useState(false);
  const [cshow, setCShow] = useState(false);

  const history = useHistory();

  useEffect(() => {
    return () => {
      setCPass('');
      setPassword('');
      setCode('');
      setUsername('');
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    const response = await AuthAPI.resetPassword(username);

    if (response.type === 'success') {
      alert(response.message);
      setShow(true);
    } else {
      alert(response.message, 'Check your username');
    }

    setLoader(false);
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setLoader(true);

    await AuthAPI.resetPasswordSubmit(username, code, password)
      .then(async (res) => {
        if (res.type === 'success') {
          const response = await AuthAPI.signIn(username, password);
          if (response.type === 'success') {
            const cred = await AuthAPI.currentCredentials();
            storage.storeToken(cred.data);
            history.push('/admin');
          } else throw response;
        } else throw res;
      })
      .catch((err) => {
        CreateNotifications('error', err.message);
        alert(err.message);
      });

    setLoader(false);
  };

  return (
    <>
      <Loader show={loader} />
      <main className="centre_content">
        <section className="header">
          <div className="header_title">
            <h3>757 Next Level</h3>
          </div>
          <div className="header_content">
            <h1>Don’t worry, we’ve got your back</h1>
            <h3>Please enter your username</h3>
          </div>
        </section>
        <section className="form_container">
          <div className="form">
            {show ? (
              <form onSubmit={(e) => handlePasswordReset(e)}>
                <Input
                  placeholder="Enter code"
                  value={code}
                  required
                  onChange={(e) => setCode(e.target.value)}
                  autoFocus
                  autoComplete="true"
                />
                <Password
                  show={pshow ? 'text' : 'password'}
                  placeholder="Enter password"
                  value={password}
                  required
                  setShow={() => setPShow(!pshow)}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="true"
                />
                <Password
                  show={cshow ? 'text' : 'password'}
                  placeholder="Confirm password"
                  value={cpass}
                  required
                  setShow={() => setCShow(!cshow)}
                  onChange={(e) => setCPass(e.target.value)}
                  autoComplete="true"
                />
                <button className="form_submit_button" type="submit">
                  Reset Password
                </button>
              </form>
            ) : (
              <form onSubmit={(e) => handleSubmit(e)}>
                <Input
                  placeholder="Enter username"
                  value={username}
                  required
                  onChange={(e) => setUsername(e.target.value)}
                  autoFocus
                  autoComplete="true"
                />
                <button className="form_submit_button" type="submit">
                  Reset Password
                </button>
              </form>
            )}
          </div>
        </section>
        <section className="btn_row_container">
          <div className="btn_row sign_in">
            <button
              onClick={() => history.push('/admin/login')}
              className="btn"
            >
              Back to Sign In
            </button>
          </div>
        </section>
      </main>
    </>
  );
}

export default ResetPassword;
