import Amplify, { Storage } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import awsmobile from "../aws-exports";
import { listLandingPageContent } from "./api/LandingPageApi";
import CreateNotifications from "./Notifications";

Amplify.configure(awsmobile);

function HeroBanner() {
  const [banner, setBanner] = useState("");

  const history = useHistory();

  useEffect(() => {
    const initialLoad = async () => {
      await listLandingPageContent()
        .then(async (res) => {
          console.log(res, "result");
          if (res.type === "success") {
            const url = await Storage.get(res.data.banner_img, {
              expires: 60000,
            });
            setBanner(url);
          } else throw res;
        })
        .catch((err) => {
          CreateNotifications("error", err.message);
        });
    };

    initialLoad();
  }, []);

  return !(
    history.location.pathname.includes("/player") ||
    history.location.pathname.includes("/admin")
  ) ? (
    <section className="banner">
      <picture>
        <img src={banner} alt="banner" />
      </picture>
    </section>
  ) : null;
}

export default HeroBanner;
