import { Storage } from "aws-amplify";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import AppButton from "../AppButton";

const INITIAL_DATA = {
  title: "",
  description: "",
  eventBanner: null,
  startdate: new Date(),
  enddate: new Date(),
  attachments: {
    files: [
      { name: "", file: null },
      { name: "", file: null },
      { name: "", file: null },
      { name: "", file: null },
      { name: "", file: null },
    ],
  },

  contact: { name: "", phone: "", email: "" },
};

function ViewRegistration({ setModalOpen, data = INITIAL_DATA }) {
  const [imageUrl, setImageUrl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    // console.log(data)
    let isSubscribed = true;
    const initialLoad = async () => {
      if (data && data.eventBanner) {
        const url = await Storage.get(data.eventBanner);
        if (!isSubscribed) return;
        setImageUrl(url);
      }
      if (data && data.attachments) {
        const fileArray = JSON.parse(data.attachments)
          .files.filter((item) => item.name.length !== 0)
          .map((item) => item.name);
        fileArray.forEach(async (item) => {
          const url = await Storage.get(item);
          setFiles((files) => [...files, url]);
        });
      }
    };
    initialLoad();
  }, [data]);

  return (
    <Modal isOpen={true}>
      {data && (
        <>
          <div style={{ flex: 1, display: "flex" }}>
            <div style={{ flex: 1, display: "flex" }}>
              <h5 style={{ fontSize: "20px", fontWeight: "400" }}>
                Registration
              </h5>
            </div>
            <div
              style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}
            >
              <AppButton
                title="X"
                buttoncolor={"red"}
                onClick={() => setModalOpen(false)}
              />
            </div>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <br />
            <table>
              <colgroup>
                <col span="1" style={{ width: "20%" }} />
                <col span="1" style={{ width: "40%" }} />
                <col span="1" style={{ width: "40%" }} />
              </colgroup>
              <tbody>
                <tr>
                  <td>
                    <p style={{ margin: "1rem 0" }}>Title of the Event : </p>
                  </td>
                  <td>{data.title}</td>
                </tr>
                <tr>
                  <td>
                    <p style={{ margin: "1rem 0" }}>Description of the Event</p>
                  </td>
                  <td>{data.description}</td>
                </tr>
                <tr>
                  <td>
                    <p style={{ margin: "1rem 0" }}>Banner of the Event</p>
                  </td>
                  <td>
                    <AppButton
                      title="View Image"
                      buttoncolor="blue"
                      onClick={() => setIsOpen(true)}
                    />
                    <Modal isOpen={isOpen}>
                      <AppButton
                        title="X"
                        buttoncolor={"red"}
                        onClick={() => setIsOpen(false)}
                      />
                      <>
                        <br />
                        <img src={imageUrl} alt={data.title} />
                      </>
                    </Modal>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p style={{ margin: "1rem 0" }}>Start date of the Event</p>
                  </td>
                  <td>{new Date(data.startdate).toLocaleDateString()}</td>
                </tr>
                <tr>
                  <td>
                    <p style={{ margin: "1rem 0" }}>End Date of the Event</p>
                  </td>
                  <td>{new Date(data.enddate).toLocaleDateString()}</td>
                </tr>
                <tr>
                  <td>
                    <p>Attachments</p>
                  </td>
                  <td>
                    <table style={{ margin: "1rem 0" }}>
                      <colgroup>
                        <col span="1" style={{ width: "60%" }} />
                        <col span="1" style={{ width: "40%" }} />
                      </colgroup>
                      {JSON.parse(data.attachments).files.map((item, index) => {
                        return item.name.length ? (
                          <tr key={index}>
                            <td>{item.name}</td>
                            <td>
                              <a href={files[index]} download>
                                <AppButton
                                  title="Download"
                                  buttoncolor="blue"
                                />
                              </a>
                            </td>
                          </tr>
                        ) : null;
                      })}
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <br />
                    <p style={{ margin: "1rem 0" }}>
                      {" "}
                      Contact Details ( SPOC of the Event)
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p style={{ margin: "1rem 0" }}>Name</p>
                  </td>
                  <td>{JSON.parse(data.contact).name}</td>
                </tr>
                <tr>
                  <td>
                    <p style={{ margin: "1rem 0" }}>Phone</p>
                  </td>
                  <td>{JSON.parse(data.contact).phone}</td>
                </tr>
                <tr>
                  <td>
                    <p style={{ margin: "1rem 0" }}>Email</p>
                  </td>
                  <td>{JSON.parse(data.contact).email}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </Modal>
  );
}

export default ViewRegistration;
