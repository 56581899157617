import React, { useEffect, useState } from "react";
import ScreenWrapper from "../ScreenWrapper";
import { getImagesByFaces, getSignedUrls } from "../api/faceAI";
import AppButton from "../AppButton";
import SearchButton from "../SearchButton";
import Modal from "react-modal";
import ImageCropper from "../ImageCropper";
import Amplify, { Storage } from "aws-amplify";
import awsmobile from "../../aws-exports";
import CreateNotifications from "../Notifications";
import ImageCard from "../Skeletons/ImageCard";
import classes from "../../assets/css/Dashboard.module.css";
import { AiOutlineClose } from "react-icons/ai";
import {
  createLogsFunction,
  listLatestLogsFunction,
  updateLogsFunction,
  deleteLogsFunction,
  getLatestLogsFunction,
} from "../../components/api/Logs";

import AuthAPI from "../api/Auth";
import { Form, Button } from "react-bootstrap";

Amplify.configure(awsmobile);

function Dashboard() {
  const [Data, setData] = useState([]);
  const [Confidence, setConfidence] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isModal, setModal] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [imgName, setImgName] = useState("");
  const [c_id, setc_id] = useState("757next");

  useEffect(() => {
    // #test
    test();
  }, []);

  const test = async () => {
    console.log("test");
    // console.log(AuthAPI.currentSession);
    var response = await listLatestLogsFunction();
    console.log(response);
    // // var response = await createLogsFunction({ username: "shiva" });
    // var response = await AuthAPI.currentAuthenticatedUser();
    // console.log(response.data.attributes.email);
    var resp = await deleteLogsFunction("3bcff891-0597-44a9-bab5-3f3fb28d767d");
  };

  const initialLoad = async () => {
    var keys = [];
    var Confidence = [];
    var response = await getImagesByFaces({
      key: "morgan.png",
      c_id: c_id,
    });
    // console.log(response);
    console.log("Faces Matched");
    if (response["data"].length == 0) {
      CreateNotifications("warning", "No Matching Images Found!");
    } else {
      CreateNotifications(
        "success",
        response["data"].length.toString() + " Matching Images Found!"
      );
    }
    var test = [];
    response["data"] &&
      response["data"].forEach((entry) => {
        keys.push(entry.Face.ExternalImageId);
        Confidence.push(entry.Similarity);
        test.push(entry.Face.FaceId);

        // console.log(entry.Face.ExternalImageId);
      });

    console.log(test);
    setConfidence(Confidence);
    // console.log(keys);
    var signedUrls = await getSignedUrls(keys);
    // console.log(signedUrls["data"]);
    setData(signedUrls["data"]);

    var response = await AuthAPI.currentSession();
    var resp = await createLogsFunction({
      username: response.data.idToken.payload.email,
      searchimageName: imgName,
      countResults: signedUrls["data"].length,
    });
    console.log(resp);

    // console.log("data", Data);

    return signedUrls["data"];
  };

  const searchMatchingFaces = async () => {
    var filename = "search-image.png";
    console.log(imageUrl);
    // const blob = imageUrl.current.files[0];
    CreateNotifications("warning", "Uploading");
    setLoading(true);
    const result = await Storage.put(filename, imageUrl);
    if ("key" in result) {
      CreateNotifications("success", "Searching Matching Faces ");
      setImageUrl(null);
      setTimeout(() => {
        setLoading(false);
      }, 4000);
      initialLoad();
      setData([]);
    }
    console.log(result);
  };

  const imageName = (image) => {
    setImgName(image);
  };

  const modalHandler = (imageUrl) => {
    setModal(!isModal);
    setModalImage(imageUrl);
  };

  return (
    <ScreenWrapper>
      <Modal isOpen={isOpen}>
        <ImageCropper
          setImage={setImageUrl}
          imageName={imageName}
          aspectRatio={1 / 1}
          close={() => setIsOpen(false)}
        />
      </Modal>
      <Modal isOpen={isModal}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <AiOutlineClose
            color="#424242"
            size={36}
            style={{ position: "absolute", top: "3%", right: "5%" }}
            onClick={modalHandler}
          />
          <img
            src={modalImage}
            style={{
              height: "50vw",
              width: "50vw",
              padding: "2rem",
            }}
            alt="..."
          />
        </div>
      </Modal>

      <div style={Style.grid}>
        <div style={Style.imageContainer}>
          <img src="/face.jpg" alt="scanner" style={Style.scanner} />
          <h6 style={Style.text}>
            <b>Search players (AI Image Search)</b>
          </h6>
        </div>
        <div style={Style.text_center}>
          Choose Player's Image you want to search
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          margin: "20px",
        }}
      >
        <Button variant="success" onClick={() => setIsOpen(true)}>
          SELECT IMAGE
        </Button>

        {imageUrl && (
          <>
            <div style={{ width: "15%", margin: "10px" }}>
              <Form.Select onChange={(e) => setc_id(e.target.value)}>
                <option value="757next">Choose Collection</option>
                <option value="757next">757 League</option>
                <option value="wrestling">Wrestling</option>
                <option value="basketball">Basketball</option>
              </Form.Select>
            </div>
            <Button
              onClick={() => searchMatchingFaces()}
              style={{
                width: "80px",
                color: "white",
                backgroundColor: "rgb(40, 127, 247)",

                marginLeft: "5px",
                borderRadius: "6px",
                border: "none",
                fontWeight: "bold",
                fontSize: "16px",
                textAlign: "center",
              }}
            >
              SEARCH
            </Button>
          </>
        )}
      </div>

      {/* <h1>{Data ? Data.length : 0}</h1> */}
      {/* {Data.length != 0 && <img style={Style.image2} src={Data[0]} />} */}
      {isLoading ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              margin: "5px",
              padding: "5px",
              justifyContent: "center",
            }}
          >
            <ImageCard />
            <ImageCard />
            <ImageCard />
            <ImageCard />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              margin: "5px",
              padding: "5px",
              justifyContent: "center",
            }}
          >
            <ImageCard />
            <ImageCard />
            <ImageCard />
            <ImageCard />
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            margin: "5px",
            padding: "5px",
            justifyContent: "center",
          }}
        >
          {Data &&
            Data.map(function (imgurl, i) {
              return (
                <>
                  {/* <p>{Confidence[i]}</p> */}

                  <div
                    style={{
                      paddingRight: "5px",
                      margin: "5px",
                      maxHeight: "400px",
                      overflow: "hidden",
                      position: "relative",
                      height: "38vh",
                      borderRadius: "8px",
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      maxWidth: "400px",
                      width: "38vh",
                      backgroundPosition: "center",
                      backgroundrepeat: "no-repeat",
                      backgroundClip: "border-box",
                      backgroundBlendMode: "exclusion",
                      backgroundSize: "cover",
                      backgroundImage: `url(${imgurl})`,
                    }}
                    className={classes.container}
                  >
                    <div
                      className={classes.div}
                      onClick={() => modalHandler(imgurl)}
                    >
                      View Full Size
                    </div>
                    <div style={Style.parent}>
                      {/* <img style={Style.image1} src={Data[0]} /> */}
                      {/* <img style={Style.image2} src="https://via.placeholder.com/50" /> */}
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      )}
      {Data && Data.forEach((imgurl) => {})}
    </ScreenWrapper>
  );
}

const Style = {
  parent: {
    position: "relative",
    top: 0,
    left: 0,
  },
  image1: {
    position: "relative",
    top: 0,
    left: 0,
    border: "1px red solid",
  },
  image2: {
    position: "absolute",
    top: "30px",
    left: "30px",
    border: "1px green solid",
  },
  grid: {
    display: "grid",
    paddingTop: "4rem",
    alignItems: "start",
    // rowGap: "3rem",
    width: "40vw",
    justifyItems: "center",
    margin: "auto",
    placeItems: "center",
  },
  text: {
    fontSize: "18px",
    textAlign: "center",
    padding: "5px",
  },
  text_center: {
    textAlign: "center",
    paddingTop: "5px",
  },
  scanner: {
    width: "6vw",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  button: {
    display: "none",
  },
};

export default Dashboard;
