import React from "react";
import { Route, Switch } from "react-router-dom";
import Login from "../admin/auth/Login";
import Logout from "../admin/auth/Logout";
import ChangePassword from "../admin/auth/ChangePassword";
import ResetPassword from "../admin/auth/ResetPassword";
import Podcasts from "../admin/Podcasts";
import Videos from "../admin/Videos";
import Home from "../Home/Home";
import ProtectedRoute from "./ProtectedRoute";
import AdminHome from "../admin/Home/AdminHome";
import Dashboard from "../admin/Dashboard";
import Registrations from "../admin/Registrations";
import Events from "../Events/Events";
import VideoJS from "../VideoJS";
import Team from "../admin/Team";
import Player from "../admin/Player/Player";
import CreatePlayer from "../admin/Player/CreatePlayer";
import UpdatePlayer from "../admin/Player/UpdatePlayer";
import CollectionHome from "../admin/Collection/CollectionHome";
import PlayerLogin from "../Pages/Players/PlayerLogin";
import PlayerDashboard from "../Pages/Players/PlayerDashboard";
import PlayerProfile from "../Pages/Players/PlayerProfile";
import PlayerSignup from "../Pages/Players/PlayerSignup";
import PlayerVerificationCode from "../Pages/Players/PlayerVerificationCode";
import PlayerUpdateProfile from "../Pages/Players/PlayerUpdateProfile";
import PlayerForgetPassword from "../Pages/Players/PlayerForgetPassword";
import PlayerForgetPasswordSubmit from "../Pages/Players/PlayerForgetPasswordSubmit";

// import ProtectedRoute from './ProtectedRoute';

function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/admin/login" component={Login} />

      <Route exact path="/logout" component={Logout} />
      <Route exact path="/admin/reset" component={ResetPassword} />
      <Route exact path="/events" component={Events} />
      <Route exact path="/admin/register" component={ChangePassword} />

      {/* Player Routers */}
      <Route exact path="/player" component={PlayerLogin} />
      <ProtectedRoute
        exact
        path="/player/dashboard"
        component={PlayerDashboard}
      />
      <ProtectedRoute exact path="/player/profile" component={PlayerProfile} />
      <Route exact path="/player/signup" component={PlayerSignup} />
      <Route
        exact
        path="/player/verification"
        component={PlayerVerificationCode}
      />
      <Route
        exact
        path="/player/forgetpassword"
        component={PlayerForgetPassword}
      />
      <Route
        exact
        path="/player/forgetpasswordverify"
        component={PlayerForgetPasswordSubmit}
      />
      <ProtectedRoute
        exact
        path="/player/updateProfile"
        component={PlayerUpdateProfile}
      />

      {/* Admin Routers */}

      <ProtectedRoute exact path="/admin" component={Dashboard} />
      <ProtectedRoute exact path="/admin/podcasts" component={Podcasts} />
      <ProtectedRoute exact path="/admin/videos" component={Videos} />
      <ProtectedRoute exact path="/admin/home" component={AdminHome} />
      <ProtectedRoute exact path="/admin/test" component={VideoJS} />
      <ProtectedRoute exact path="/admin/team" component={Team} />
      <ProtectedRoute exact path="/admin/player" component={Player} />
      <ProtectedRoute
        exact
        path="/admin/createplayer"
        component={CreatePlayer}
      />
      <ProtectedRoute
        exact
        path="/admin/updateplayer/:id"
        component={UpdatePlayer}
      />
      <ProtectedRoute
        exact
        path="/admin/registrations"
        component={Registrations}
      />
      <ProtectedRoute
        exact
        path="/admin/collections"
        component={CollectionHome}
      />
      <Route exact path="/admin/*" component={Login} />
    </Switch>
  );
}

export default Routes;
